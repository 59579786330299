import { Loading } from "@components";
import { Descriptions, message, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import dayjs from "dayjs";
import * as API from "@api";

const { Item } = Descriptions;

export default function Sweetness({ className, ...props }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    API.Sweetnesses.getOne({ id: props?.id })
      .then(async (res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch(() => {
        message.error("Error while getting data, please try again later", 2);
        setLoading(false);
      });
  }, []);

  if (loading || !data) {
    return <Loading compact />;
  }

  return (
    <div key={props?.id} className={classNames("w-full", className)}>
      <Descriptions
        extra={
          <Link to={`/sweetnesses/edit/${data?.id}`}>
            <Button icon={<EditOutlined />}>Edit Sweetness Type</Button>
          </Link>
        }
        size="small"
        layout="vertical"
        column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
        title="Wine sweetness Info"
        bordered
        colon={false}
      >
        <Item label="Name">{data?.name}</Item>
        <Item label="Date created">
          {dayjs(data?.created_at).format("HH:mm · DD MMM YYYY")}
        </Item>
        <Item label="Last updated" span={3}>
          {dayjs(data?.updated_at).format("HH:mm · DD MMM YYYY")}
        </Item>
      </Descriptions>
    </div>
  );
}
