import { Input } from "antd";

export default function Email({ onChange }) {
  return (
    <Input.Search
      placeholder="Search by Email"
      allowClear
      onSearch={(v) => onChange("email", v)}
      enterButton="Search"
    />
  );
}
