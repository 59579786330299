import { Form, Input } from "antd";

export default function Alcohol(props) {
  return (
    <Form.Item {...props} name="sku" className="w-full" label="SKU">
      <Input
        controls={false}
        className="w-full"
        placeholder="Enter product SKU"
      />
    </Form.Item>
  );
}
