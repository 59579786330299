import { Card, Button } from "antd";
import { Breadcrumb } from "@components";
import { Analytics, Orders, Products } from "@modules";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import Fade from "react-reveal/Fade";

export default function Dashboard() {
  return (
    <div className="container content-max">
      <Breadcrumb data={[{ title: "Dashboard", href: "/" }]} />
      <h1 className="mb-8 text-3xl">Dashboard</h1>
      <Fade bottom distance="30px" cascade duration={400}>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-6 xl:col-span-4">
            <Card
              className="overflow-scroll"
              bordered={false}
              title="New orders"
              extra={<Link to="/orders">View All</Link>}
            >
              <Analytics.Cards.Orders title="Since yesterday" />
            </Card>
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-4">
            <Card
              className="overflow-scroll"
              bordered={false}
              title="Wine products"
              extra={<Link to="/products">View All</Link>}
            >
              <Analytics.Cards.Products title="Published" />
            </Card>
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-4">
            <Card
              className="overflow-scroll"
              bordered={false}
              title="Blog articles"
              extra={<Link to="/articles">View All</Link>}
            >
              <Analytics.Cards.Posts title="Published" />
            </Card>
          </div>
          <div className="col-span-12">
            <Card
              title="Orders"
              className="h-full"
              extra={[<Link to="/orders">View All</Link>]}
            >
              <Orders.List showFilters={[]} />
            </Card>
          </div>
          <div className="col-span-12">
            <Card
              title="Wines"
              className="h-full"
              extra={
                <Link to="/products/new">
                  <Button icon={<PlusOutlined />}>Add New Wine</Button>
                </Link>
              }
            >
              <Products.List showFilters={[]} />
            </Card>
          </div>
        </div>
      </Fade>
    </div>
  );
}
