import { Form, Select } from "antd";
import { CURRENCIES } from "@utils/products";

export default function Currency(props) {
  const data = [];

  Object.keys(CURRENCIES).forEach(function (key) {
    data.push(CURRENCIES[key]);
  });

  return (
    <Form.Item
      {...props}
      name="currency"
      className="w-full"
      label="Price Currency"
      initialValue="eur"
      rules={[
        {
          required: true,
          message: "Currency is required",
        },
      ]}
    >
      <Select
        placeholder="Select one"
        className="w-full"
        allowClear
        showSearch
        filterOption={(input, option) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={data.map((val) => {
          return {
            label: `${val.code} - ${val.name}`,
            value: val.code.toLowerCase(),
          };
        })}
      />
    </Form.Item>
  );
}
