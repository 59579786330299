import { Select } from "antd";

export default function Status(params) {
  return (
    <Select
      className="w-full"
      placeholder="Select Status"
      allowClear
      showSearch
      filterOption={(input, option) =>
        option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(v) => params.onChange("active", v)}
      options={[
        {
          value: "0",
          label: "Inactive (Draft)",
        },
        {
          value: "1",
          label: "Published",
        },
      ]}
    />
  );
}
