import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import AuthContextProvider from "@contexts";
import config from "@config";
import { ConfigProvider } from "antd";
import "antd/dist/reset.css";
import "@styles/global.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ConfigProvider {...config.antd}>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </ConfigProvider>
);
