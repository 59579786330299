import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@components";
import { message, Form } from "antd";
import DataForm from "./form";
import * as API from "@api";

export default function EditData() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    API.Orders.getOne({ id }).then((res) => {
      if (res.error || !res) {
        return message.error("Unable to load data, please try again later.", 2);
      }

      setData(res.data);
      setLoading(false);
    });
  }, []);

  if (!data) {
    return <Loading compact />;
  }

  const onSave = async (values) => {
    setLoading(true);
    const products = form.getFieldValue("products");

    const normalizedValues = Object.entries(values).reduce(
      (acc, [key, value]) => {
        acc[key] = value !== undefined ? value : null;
        return acc;
      },
      {}
    );

    API.Orders.update({
      id,
      ...normalizedValues,
      products,
    })
      .then((res) => {
        if (res?.error || !res?.data) {
          throw new Error(res?.message || "Error updating data");
        }

        return message.success("Order updated successfully", 1);
      })
      .catch((err) => message.error(err.message || "Error updating data", 2))
      .finally(() => setLoading(false));
  };

  return (
    <Form
      form={form}
      onFinish={onSave}
      onFinishFailed={(err) =>
        err?.errorFields?.map((msg) => message.error(msg.errors[0], 2))
      }
      layout="vertical"
      className="w-full form-container"
      initialValues={data}
    >
      <DataForm form={form} data={data} loading={loading} />
    </Form>
  );
}
