import { Form, Input } from "antd";

export default function DataFormItem(props) {
  return (
    <Form.Item
      {...props}
      name="email"
      className="w-full"
      label="Customer email"
      rules={[
        {
          required: true,
          type: "email",
          message: "Please enter valid customer email",
        },
      ]}
    >
      <Input
        controls={false}
        minLength={3}
        className="w-full"
        placeholder="Enter customer email"
      />
    </Form.Item>
  );
}
