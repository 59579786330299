import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@components";
import { message, Form } from "antd";
import DataForm from "./form";
import * as API from "@api";

export default function EditData() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    API.Articles.getOne({ id }).then((res) => {
      if (res.error || !res) {
        return message.error("Unable to load data, please try again later.", 2);
      }

      setData({ ...res.data, tags: res?.data?.tags?.map((tag) => tag.id) });
      setLoading(false);
    });
  }, []);

  if (!data) {
    return <Loading compact />;
  }

  const onSave = async (values) => {
    setLoading(true);

    const content = form.getFieldValue("content");
    const meta = form.getFieldValue("meta");
    const image = form.getFieldValue("image");
    const normalizedValues = Object.entries(values).reduce(
      (acc, [key, value]) => {
        acc[key] = value !== undefined ? value : null;
        return acc;
      },
      {}
    );

    API.Articles.update({
      id,
      ...normalizedValues,
      meta,
      content,
      image,
    })
      .then(async (res) => {
        if (res?.error || !res?.data) {
          throw new Error(res?.message || "Error updating article");
        }

        await API.Revalidate.staticPages();

        // Old article revalidate
        await API.Revalidate.articles({ slug: data?.slug });

        // Check if title changed
        if (data?.slug !== res?.data?.slug) {
          await API.Revalidate.articles({ slug: res?.data?.slug });
        }

        return message.success("Article updated", 1, () =>
          window.location.reload()
        );
      })
      .catch((err) => message.error(err.message || "Error updating article", 2))
      .finally(() => setLoading(false));
  };

  const onPublish = () => {
    onSave({
      ...form.getFieldsValue(),
      status: "published",
    });
  };

  const onUnpublish = async () => {
    setLoading(true);
    API.Articles.update({
      id,
      status: "draft",
    })
      .then(async (res) => {
        if (res?.error || !res?.data) {
          throw new Error(res?.message || "Error unpublishing article");
        }

        await API.Revalidate.staticPages();

        // Old article revalidate
        await API.Revalidate.articles({ slug: data?.slug });

        return message.success("Article unpublished", 1, () =>
          window.location.reload()
        );
      })
      .catch((err) =>
        message.error(err.message || "Error unpublishing article", 2)
      )
      .finally(() => setLoading(false));
  };

  const onDelete = () => {
    setLoading(true);

    API.Articles.destroy({ id })
      .then(async (res) => {
        if (res?.error || !res?.data) {
          throw new Error(res?.message || "Error deleting article");
        }

        await API.Revalidate.staticPages();

        // Old article revalidate
        await API.Revalidate.articles({ slug: data?.slug });

        return message.success(
          "Article deleted successfully",
          1,
          () => (window.location.href = "/articles")
        );
      })
      .catch((err) =>
        message.error(err.message || "Error deleting article", 2)
      );
  };

  return (
    <Form
      form={form}
      onFinish={onSave}
      onFinishFailed={(err) =>
        err?.errorFields?.map((msg) => message.error(msg.errors[0], 2))
      }
      layout="vertical"
      className="w-full form-container"
      initialValues={data}
    >
      <DataForm
        onDelete={onDelete}
        form={form}
        data={data}
        loading={loading}
        onPublish={onPublish}
        onUnpublish={onUnpublish}
      />
    </Form>
  );
}
