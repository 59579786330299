import { Modal, Select, message } from "antd";
import { useEffect, useState } from "react";
import WidgetBlocks from "../blocks";

export default function NewWidgetModal({
  open,
  widgets,
  onCancel,
  onAfterAdd,
}) {
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(null);
  }, [open]);

  const onAdd = () => {
    const widget = widgets.find((w) => w.data.key === data.type);
    let hasErrors = false;

    widget?.data?.inputs?.map((input) => {
      const required = input?.required || false;

      if (required) {
        if (!data?.data?.[input.key]) {
          hasErrors = true;
          return message.error(`Field '${input.label}' is required`, 2);
        }

        if (input.type === "images" && data?.data?.[input.key]?.length === 0) {
          hasErrors = true;
          return message.error(`Field '${input.label}' is required`, 2);
        }
      }
    });

    if (!hasErrors) {
      onAfterAdd(data);
      message.info("Content widget added successfully", 1);
    }
  };

  return (
    <Modal
      destroyOnClose
      title="Add new content widget"
      centered
      open={open}
      onCancel={onCancel}
      okText="Add Now"
      onOk={onAdd}
      okButtonProps={{ disabled: !data }}
    >
      <div className="grid grid-cols-12 gap-4 my-5">
        <div className="col-span-12">
          <Select
            onChange={(key) =>
              setData({
                type: key,
                name: widgets.find((w) => w.data.key === key)?.data?.name,
              })
            }
            className="w-full"
            placeholder="Select content type"
          >
            {widgets.map((item, key) => (
              <Select.Option
                key={`${item.data.key}-${key}`}
                value={item.data.key}
              >
                {item.data.name}
              </Select.Option>
            ))}
          </Select>
          <WidgetBlocks
            widgets={widgets}
            data={data}
            onChange={(key, value) =>
              setData({ ...data, data: { ...data?.data, [key]: value } })
            }
            onOptionChange={(key, value) =>
              setData({ ...data, options: { ...data?.options, [key]: value } })
            }
          />
        </div>
      </div>
    </Modal>
  );
}
