import { Settings } from "@modules";
import { Breadcrumb } from "@components";

export default function IndexPage() {
  return (
    <div className="container max-content">
      <Breadcrumb
        data={[
          { title: "Contact Us Page Settings", href: "/settings/contacts" },
        ]}
      />
      <div className="flex justify-between items-center flex-wrap mb-5">
        <h1 className="text-3xl">Contact Us Page Settings</h1>
      </div>
      <Settings type="page:contacts" />
    </div>
  );
}
