import Title from "./title";
import Subtitle from "./subtitle";
import Category from "./category";
import Author from "./author";

export default function Details() {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <Title />
      </div>
      <div className="col-span-12">
        <Subtitle />
      </div>
      <div className="col-span-12 lg:col-span-6">
        <Category />
      </div>
      <div className="col-span-12 lg:col-span-6">
        <Author />
      </div>
    </div>
  );
}
