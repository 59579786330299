import { Divider, Card, Button, Popconfirm } from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import Password from "./details/password";
import Details from "./details";

export default function EditEntry({
  data,
  loading,
  onPasswordChange,
  onDelete,
}) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 lg:col-span-6 h-full">
        <Card title="Change Details" className="h-full" bordered={false}>
          <div className="grid grid-cols-12 gap-x-4 gap-y-2">
            <div className="col-span-12">
              <Details />
            </div>
          </div>
          <Divider />
          <div className="flex flex-wrap justify-between items-center">
            <Button
              loading={loading}
              type="primary"
              icon={<SaveOutlined />}
              htmlType="submit"
            >
              Save
            </Button>
            <Popconfirm
              okText="Yes, delete"
              title="Delete user?"
              description="User will be deleted permanently and cannot be recovered."
              onConfirm={onDelete}
            >
              <Button
                loading={loading}
                type="text"
                icon={<DeleteOutlined />}
                danger
              >
                Delete User
              </Button>
            </Popconfirm>
          </div>
        </Card>
      </div>
      <div className="col-span-12 lg:col-span-6">
        <Card title="Change Password" bordered={false}>
          <div className="grid grid-cols-12 gap-x-4 gap-y-2">
            <div className="col-span-12">
              <Password />
            </div>
          </div>
          <div>
            <Divider />
            <Button
              onClick={onPasswordChange}
              disabled={loading}
              type="primary"
            >
              Change Password
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
}
