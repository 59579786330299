import { useEffect, useState } from "react";
import { Table, Button, message, Tag, Modal } from "antd";
import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import { pathOr, includes } from "ramda";
import View from "../view";
import dayjs from "dayjs";
import { Orders as OrdersUtils } from "@utils";
import * as API from "@api";
import * as Filters from "./filters";

export default function DataTable({
  showHeader = true,
  showFilters = ["search", "id", "dates", "email", "status"],
  defaultFilterValues = null,
  pageSize = 10,
  showPagination = true,
}) {
  const [orderId, setOrderId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({
    id: pathOr(undefined, ["id"], defaultFilterValues),
    created_at: pathOr(undefined, ["created_at"], defaultFilterValues),
    email: pathOr(undefined, ["email"], defaultFilterValues),
    status: pathOr(undefined, ["status"], defaultFilterValues),
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
    showTotal: (total) => `Entries found: ${total}`,
  });

  useEffect(() => {
    getTableData();
  }, [filter, search]);

  async function getTableData(params = pagination, _, sorter) {
    setLoading(true);
    const _res = await API.Orders.getAll({
      s: search,
      filter,
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
      order: sorter?.field && {
        [sorter?.field]: sorter?.order === "ascend" ? "ASC" : "DESC",
      },
    });

    if (!_res.data) {
      return message.error("Error while getting data, please try again later");
    }

    setTableData(_res.data.rows);
    setPagination({
      ...params,
      total: _res.data.count,
      showTotal: (total) => `Entries found: ${total}`,
    });
    setLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter(
      { ...filter, [f]: v || undefined },
      setPagination({ ...pagination, current: 1 })
    );
  }

  function onSearch(v) {
    setSearch(v, setPagination({ ...pagination, current: 1 }));
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (data) => <Tag className="m-0 mr-1">{data}</Tag>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      dataIndex: "info",
      key: "info",
      align: "left",
      width: 300,
      title: "Customer",
      render: (_, data) => (
        <div>
          <span className="block text-base font-medium text-black">
            {data?.name}
          </span>
          <span className="block text-sm font-normal text-neutral-400">
            Email:{" "}
            <i className="not-italic text-neutral-500">
              {data?.email || "N/a"}
            </i>
          </span>
          <span className="block text-sm font-normal text-neutral-400">
            Phone:{" "}
            <i className="not-italic text-neutral-500">
              {data?.phone || "N/a"}
            </i>
          </span>
        </div>
      ),
    },
    {
      dataIndex: "country",
      key: "country",
      align: "center",
      title: "Country",
      render: (data) => (
        <Tag className="m-0">{data?.name || "Not specified"}</Tag>
      ),
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      render: (data) => (
        <span className="m-0 leading-tight">
          <i className="text-neutral-500 not-italic">
            {dayjs(data).format("HH:mm")}
          </i>{" "}
          &middot; {dayjs(data).format("DD MMM YYYY")}
        </span>
      ),
      sorter: (a, b) => dayjs(a.created_at) - dayjs(b.created_at),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (data) => (
        <Tag className="m-0" color={OrdersUtils.orderStatuses(data).tagColor}>
          {OrdersUtils.orderStatuses(data).name}
        </Tag>
      ),
      sorter: (a, b) => a?.status.localeCompare(b?.status),
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (_, all) => (
        <Button onClick={() => setOrderId(all.id)} icon={<EyeOutlined />}>
          View
        </Button>
      ),
    },
  ];

  return (
    <div className="grid grid-cols-12 gap-4">
      {includes("search", showFilters) && (
        <div className="col-span-12 lg:col-span-4">
          <Filters.Search onSearch={onSearch} />
        </div>
      )}
      {includes("email", showFilters) && (
        <div className="col-span-12 lg:col-span-4">
          <Filters.Email onChange={onFilterChange} />
        </div>
      )}
      {includes("id", showFilters) && (
        <div className="col-span-12 lg:col-span-4">
          <Filters.ID onChange={onFilterChange} />
        </div>
      )}
      {includes("dates", showFilters) && (
        <div className="col-span-12 lg:col-span-6">
          <Filters.Dates onChange={onFilterChange} />
        </div>
      )}
      {includes("status", showFilters) && (
        <div className="col-span-12 lg:col-span-6">
          <Filters.Status onChange={onFilterChange} />
        </div>
      )}
      <div className="col-span-12">
        <Table
          showHeader={showHeader}
          onChange={getTableData}
          pagination={showPagination && pagination}
          dataSource={tableData}
          loading={loading}
          className="rounded-sm overflow-hidden w-full"
          rowKey="id"
          scroll={{ x: "max-content" }}
          columns={columns}
        />
      </div>
      <Modal
        destroyOnClose
        width={950}
        open={!!orderId}
        onCancel={() => setOrderId(null)}
        closable={false}
        okButtonProps={{ style: { display: "none" } }}
        cancelText="Done"
      >
        <View orderId={orderId} />
      </Modal>
    </div>
  );
}
