import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@components";
import { message, Form } from "antd";
import DataForm from "./form";
import * as API from "@api";

export default function EditData() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    API.Varieties.getOne({ id }).then((res) => {
      if (res.error || !res) {
        return message.error("Unable to load data, please try again later.", 2);
      }

      setData(res.data);
      setLoading(false);
    });
  }, []);

  if (!data) {
    return <Loading compact />;
  }

  const onSave = async (values) => {
    setLoading(true);

    const meta = form.getFieldValue("meta");
    const normalizedValues = Object.entries(values).reduce(
      (acc, [key, value]) => {
        acc[key] = value !== undefined ? value : null;
        return acc;
      },
      {}
    );

    API.Varieties.update({
      id,
      ...normalizedValues,
      meta,
    })
      .then(async (res) => {
        if (res?.error || !res?.data) {
          throw new Error(res?.message || "Error updating variety");
        }

        await API.Revalidate.staticPages();
        await API.Revalidate.allWines();

        return message.success("Variety updated successfully", 1);
      })
      .catch((err) => message.error(err.message || "Error updating variety", 2))
      .finally(() => setLoading(false));
  };

  const onDelete = () => {
    setLoading(true);

    API.Varieties.destroy({ id })
      .then(async (res) => {
        if (res?.error || !res?.data) {
          throw new Error(res?.message || "Error deleting variety");
        }

        await API.Revalidate.staticPages();
        await API.Revalidate.allWines();

        return message.success(
          "Variety deleted successfully",
          1,
          () => (window.location.href = "/varieties")
        );
      })
      .catch((err) =>
        message.error(err.message || "Error deleting variety", 2)
      );
  };

  return (
    <Form
      form={form}
      onFinish={onSave}
      onFinishFailed={(err) =>
        err?.errorFields?.map((msg) => message.error(msg.errors[0], 2))
      }
      layout="vertical"
      className="w-full form-container"
      initialValues={data}
    >
      <DataForm onDelete={onDelete} form={form} data={data} loading={loading} />
    </Form>
  );
}
