import axios from "axios";
import qs from "qs";
import * as Auth from "./auth";
import * as Orders from "./orders";
import * as Articles from "./articles";
import * as Products from "./products";
import * as Colours from "./colours";
import * as Sweetnesses from "./sweetnesses";
import * as Varieties from "./varieties";
import * as Countries from "./countries";
import * as Regions from "./regions";
import * as Producers from "./producers";
import * as Projects from "./projects";
import * as Static from "./static";
import * as Languages from "./languages";
import * as ArticleCategories from "./article_categories";
import * as Tags from "./tags";
import * as Users from "./users";
import * as Settings from "./settings";
import * as Revalidate from "./revalidate";

axios.defaults.withCredentials = true;

axios.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  };

  config.paramsSerializer = (params) => {
    return qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false,
    });
  };

  return config;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export {
  Auth,
  Orders,
  Articles,
  Products,
  Colours,
  Sweetnesses,
  Varieties,
  Countries,
  Regions,
  Producers,
  Projects,
  Static,
  Languages,
  ArticleCategories,
  Tags,
  Users,
  Settings,
  Revalidate,
};
