import { Card, Segmented, Modal } from "antd";
import Contacts from "./contacts";
import Legal from "./legal";
import { Loading } from "@components";
import { LANGUAGES } from "@utils/languages";
import { useState, useEffect } from "react";

export default function Settings({ type = "page:home" }) {
  const [langs, setLangs] = useState([]);
  const [lang, setLang] = useState("en");

  useEffect(() => {
    LANGUAGES().then((res) => setLangs(res?.data?.rows || []));
  }, []);

  if (langs?.length === 0) {
    return <Loading />;
  }

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <Card bordered={false} title="Company Contacts">
          <Contacts />
        </Card>
      </div>
      <div className="col-span-12">
        <Card
          bordered={false}
          title="Legal Information"
          key={lang}
          extra={
            <Segmented
              className="m-0"
              value={lang}
              onChange={(code) => {
                if (code !== lang && code !== "en") {
                  const selected = langs.find((l) => l.code === code);
                  return Modal.confirm({
                    title: "Proceed to " + selected.name + " version?",
                    content:
                      "Please save your changes before switching to another language.",
                    okText: "Yes",
                    onOk: () => setLang(code),
                  });
                }

                setLang(code);
              }}
              options={langs?.map((l) => ({
                value: l.code,
                label: l.name + " Version",
              }))}
            />
          }
        >
          <Legal lang={lang} />
        </Card>
      </div>
    </div>
  );
}
