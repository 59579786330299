import { Divider, Card, Button, Popconfirm } from "antd";
import {
  SaveOutlined,
  DeleteOutlined,
  StopOutlined,
  ToTopOutlined,
} from "@ant-design/icons";
import Details from "./details";
import Photos from "./photos";
import Editor from "./editor";
import Language from "./details/language";
import Tags from "./details/tags";

export default function EditEntry({
  data,
  loading,
  form,
  onDelete,
  onPublish,
  onUnpublish,
}) {
  const status = form.getFieldValue("status");
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 lg:col-span-6">
        <Card
          title="Article Details"
          extra={
            <div className="my-1 flex flex-wrap justify-end items-center">
              <span className="block mr-3 text-sm text-neutral-500">
                Language:
              </span>
              <Language />
            </div>
          }
          bordered={false}
        >
          <div className="grid grid-cols-12 gap-x-4 gap-y-2">
            <div className="col-span-12">
              <Photos form={form} />
            </div>
            <div className="col-span-12">
              <Details />
            </div>
            <div className="col-span-12">
              <Divider className="mt-2 mb-5">Tags</Divider>
              <Tags form={form} />
            </div>
            <div className="col-span-12">
              <Divider className="mt-2 mb-5">Content</Divider>
              <Editor form={form} />
            </div>
          </div>
          <Divider />
          <div className="flex flex-wrap justify-between items-center gap-4">
            <div className="flex flex-wrap justify-start items-center gap-4">
              {status === "draft" && (
                <Button
                  loading={loading}
                  type="primary"
                  icon={<ToTopOutlined />}
                  onClick={onPublish}
                >
                  Save & Publish
                </Button>
              )}
              <Button
                loading={loading}
                type={status === "draft" ? "text" : "primary"}
                icon={<SaveOutlined />}
                htmlType="submit"
              >
                Save
              </Button>
              {status === "published" && (
                <Button
                  loading={loading}
                  type="text"
                  danger
                  icon={<StopOutlined />}
                  onClick={onUnpublish}
                >
                  Unpublish
                </Button>
              )}
            </div>
            <Popconfirm
              okText="Yes, delete"
              title="Delete wine?"
              description="Product will be deleted, you won't be able to restore it."
              onConfirm={onDelete}
            >
              <Button
                disabled={loading}
                type="text"
                icon={<DeleteOutlined />}
                danger
              >
                Delete Article
              </Button>
            </Popconfirm>
          </div>
        </Card>
      </div>
    </div>
  );
}
