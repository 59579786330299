export default function NotFound() {
  return (
    <div className="flex w-full h-full justify-center align-middle p-5">
      <div className="mt-16">
        <h1 className="text-2xl text-center">Page not found</h1>
        <p className="text-center text-base max-w-2xl mt-8">
          The page you are looking for does not exist. Please return back or go
          to <a href="/">Homepage</a>.
        </p>
      </div>
    </div>
  );
}
