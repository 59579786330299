import { useState, useEffect } from "react";
import { Loading } from "@components";
import { message, Form, Button } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import DataForm from "./form";
import * as API from "@api";

export default function Meta() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    API.Settings.getOne({ type: "global:contacts" }).then((res) => {
      if (res.error || !res) {
        return message.error("Unable to load data, please try again later.", 2);
      }

      setData(res?.data?.data);
      setLoading(false);
    });
  }, []);

  if (!data) {
    return <Loading compact />;
  }

  const onSave = async (values) => {
    setLoading(true);
    const normalizedValues = Object.entries(values).reduce(
      (acc, [key, value]) => {
        acc[key] = value !== undefined ? value : null;
        return acc;
      },
      {}
    );
    API.Settings.update({
      type: "global:contacts",
      data: {
        ...data,
        data: {
          ...data.data,
          ...normalizedValues,
        },
      },
    })
      .then(async (res) => {
        if (res?.error || !res?.data) {
          throw new Error(res?.message || "Error updating settings");
        }

        await API.Revalidate.staticPages();

        return message.success("Settings updated successfully", 1);
      })
      .catch((err) =>
        message.error(err.message || "Error updating settings", 2)
      )
      .finally(() => setLoading(false));
  };

  return (
    <Form
      form={form}
      onFinish={onSave}
      onFinishFailed={(err) =>
        err?.errorFields?.map((msg) => message.error(msg.errors[0], 2))
      }
      layout="vertical"
      className="w-full form-container"
      initialValues={data?.data || null}
    >
      <DataForm form={form} data={data} loading={loading} />
      <Button
        className="mt-5"
        loading={loading}
        type="primary"
        icon={<SaveOutlined />}
        htmlType="submit"
      >
        Save
      </Button>
    </Form>
  );
}
