import { Form, Select } from "antd";
import { WINE_BOTTLE_VOLUMES } from "@utils/products";

export default function Volume(props) {
  const data = WINE_BOTTLE_VOLUMES;

  return (
    <Form.Item
      {...props}
      name="volume"
      className="w-full"
      label="Bottle Volume"
      initialValue={750}
    >
      <Select
        placeholder="Select one"
        className="w-full"
        allowClear
        showSearch
        filterOption={(input, option) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={data.map((val) => {
          return {
            label: val.label,
            value: val.value,
          };
        })}
      />
    </Form.Item>
  );
}
