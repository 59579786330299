export const ORDER_STATUSES = {
  NEW: "new",
  PROCESSING: "processing",
  PENDING_PAYMENT: "pending_payment",
  SHIPPING: "shipping",
  REFUNDED: "refunded",
  CANCELLED: "cancelled",
  COMPLETED: "completed",
};

export const ORDER_STATUS_LABELS = {
  new: "New",
  processing: "Processing",
  pending_payment: "Pending Payment",
  shipping: "Shipping",
  refunded: "Refunded",
  cancelled: "Cancelled",
  completed: "Completed",
};

export const orderStatuses = (type) => {
  switch (type) {
    case ORDER_STATUSES.NEW:
      return {
        name: "New",
        tagColor: "orange",
        isDanger: false,
      };
    case ORDER_STATUSES.PROCESSING:
      return {
        name: "Processing",
        tagColor: "blue",
        isDanger: false,
      };
    case ORDER_STATUSES.PENDING_PAYMENT:
      return {
        name: "Awaiting Payment",
        tagColor: "orange",
        isDanger: false,
      };
    case ORDER_STATUSES.SHIPPING:
      return {
        name: "Shipping",
        tagColor: "blue",
        isDanger: false,
      };
    case ORDER_STATUSES.REFUNDED:
      return {
        name: "Refunded",
        tagColor: "default",
        isDanger: false,
      };
    case ORDER_STATUSES.CANCELLED:
      return {
        name: "Cancelled",
        tagColor: "red",
        isDanger: true,
      };
    case ORDER_STATUSES.COMPLETED:
      return {
        name: "Completed",
        tagColor: "green",
        isDanger: false,
      };
    default:
      return {
        name: type?.toUpperCase(),
        tagColor: "default",
        isDanger: false,
      };
  }
};
