import { Input } from "antd";

export default function Search({ onSearch }) {
  return (
    <Input.Search
      placeholder="Search by Name or Email"
      allowClear
      onSearch={onSearch}
      enterButton="Search"
    />
  );
}
