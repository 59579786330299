import { Loading } from "@components";
import { Descriptions, message, Tag, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import dayjs from "dayjs";
import config from "@config";
import * as API from "@api";

const { Item } = Descriptions;

export default function Product({ className, ...props }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    API.Products.getOne({ id: props?.productId })
      .then(async (res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch(() => {
        message.error("Error while getting data, please try again later", 2);
        setLoading(false);
      });
  }, []);

  if (loading || !data) {
    return <Loading compact />;
  }

  return (
    <div key={props?.productId} className={classNames("w-full", className)}>
      <Descriptions
        extra={
          <Link to={`/products/edit/${data?.id}`}>
            <Button icon={<EditOutlined />}>Edit Product</Button>
          </Link>
        }
        size="small"
        layout="vertical"
        column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
        title="Product Info"
        bordered
        colon={false}
      >
        <Item label="SKU">{data?.sku && <Tag>{data?.sku}</Tag>}</Item>
        <Item label="Status">
          <Tag color={data?.active ? "green" : "orange"}>
            {data?.active ? "Published" : "Draft"}
          </Tag>
        </Item>
        <Item label="Name">{data?.name}</Item>
        <Item label="Price">
          {new Intl.NumberFormat(
            "en-GB",
            {
              style: "currency",
              currency: data?.currency || "EUR",
            },
            { maximumFractionDigits: 2, minimumFractionDigits: 2 }
          ).format(data?.price || 0)}
        </Item>
        <Item label="Alcohol">{data?.alcohol || "N/a"}%</Item>
        <Item label="Project">{data?.Project?.name || "N/a"}</Item>
        <Item label="Color">{data?.colour?.name || "N/a"}</Item>
        <Item label="Variety">{data?.variety?.name || "N/a"}</Item>
        <Item label="Sweetness">{data?.sweetness?.name || "N/a"}</Item>
        <Item label="Producer">{data?.producer?.name || "N/a"}</Item>
        <Item label="Region">{data?.region?.name || "N/a"}</Item>
        <Item label="Product created">
          {dayjs(data?.created_at).format("HH:mm · DD MMM YYYY")}
        </Item>
        <Item label="Last updated" span={3}>
          {dayjs(data?.updated_at).format("HH:mm · DD MMM YYYY")}
        </Item>
        <Item span={3} label="Product images">
          <div className="flex flex-wrap justify-start items-center gap-4 py-2">
            {data?.images?.map((image) => (
              <a href={`${config.api}/static/img/${image}`} target="_blank">
                <img
                  loading="lazy"
                  src={`${config.api}/static/img/thumb-${image}`}
                  alt="Product"
                  className="block mx-auto w-24 h-24 object-contain rounded-sm bg-white border border-solid border-neutral-100 hover:border-neutral-200 transition-normal"
                />
              </a>
            ))}
          </div>
        </Item>
        <Item span={3} label="Product description">
          <div dangerouslySetInnerHTML={{ __html: data?.description }} />
        </Item>
      </Descriptions>
    </div>
  );
}
