import { Form, Input } from "antd";

export default function Title(props) {
  return (
    <Form.Item
      {...props}
      name="title"
      className="w-full"
      label="Title"
      rules={[
        {
          required: true,
          message: "Title is required",
        },
      ]}
    >
      <Input
        controls={false}
        minLength={3}
        className="w-full"
        placeholder="Enter article title"
      />
    </Form.Item>
  );
}
