import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import * as API from "@api";

export default function DataFormItem(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    API.Countries.getAll({ limit: 1000 }).then((res) => {
      if (res.error || !res) {
        return;
      }

      setData(res?.data?.rows || []);
    });
  }, []);

  return (
    <Form.Item {...props} name="country_id" className="w-full" label="Country">
      <Select
        placeholder="Select one"
        className="w-full"
        showSearch
        allowClear
        filterOption={(input, option) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={data.map((val) => {
          return {
            label: val.name,
            value: val.id,
          };
        })}
      />
    </Form.Item>
  );
}
