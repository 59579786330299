import { Form, Input } from "antd";

export default function DataFormItem(props) {
  return (
    <Form.Item
      {...props}
      name="phone"
      className="w-full"
      label="Customer phone"
    >
      <Input
        controls={false}
        minLength={3}
        className="w-full"
        placeholder="Enter customer phone"
      />
    </Form.Item>
  );
}
