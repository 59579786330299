import { Form, Input } from "antd";

export default function Email(props) {
  return (
    <Form.Item
      {...props}
      name="email"
      className="w-full"
      label="Email"
      rules={[
        {
          required: true,
          type: "email",
          message: "Enter valid email",
        },
      ]}
    >
      <Input className="w-full" placeholder="Enter user email" />
    </Form.Item>
  );
}
