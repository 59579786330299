import { Button, Card, Segmented } from "antd";
import { Articles } from "@modules";
import { Breadcrumb, Loading } from "@components";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { LANGUAGES } from "@utils/languages";
import { useEffect, useState } from "react";

export default function ListPage() {
  const [langs, setLangs] = useState([]);
  const [lang, setLang] = useState(1);

  useEffect(() => {
    LANGUAGES().then((res) => setLangs(res?.data?.rows || []));
  }, []);

  if (langs?.length === 0) {
    return <Loading />;
  }

  return (
    <div className="container max-content">
      <Breadcrumb data={[{ title: "Articles", href: "/articles" }]} />
      <div className="flex justify-between items-center flex-wrap mb-5">
        <h1 className="text-3xl">Articles</h1>
        <Link to="/articles/new">
          <Button className="m-0" icon={<PlusOutlined />} type="primary">
            Add New
          </Button>
        </Link>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Card bordered={false}>
            <Segmented
              className="mb-7"
              block
              onChange={(id) => setLang(id)}
              options={langs?.map((l) => ({
                value: l.id,
                label: l.name + " Articles",
              }))}
            />
            <Articles.List
              key={lang}
              defaultFilterValues={{ language_id: lang }}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}
