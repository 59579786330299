import style from "./loading.module.scss";
import classNames from "classnames";
import { LoadingOutlined } from "@ant-design/icons";

export default function Loading({ className, compact }) {
  return (
    <div
      className={classNames(style.container, className, {
        [style.compact]: compact,
      })}
    >
      <LoadingOutlined className={style.spinner} />
    </div>
  );
}
