import { Divider, Card, Button, Popconfirm } from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import Details from "./details";

export default function EditEntry({ loading, onDelete }) {
  return (
    <Card bordered={false}>
      <div className="grid grid-cols-12 gap-x-4 gap-y-2">
        <div className="col-span-12">
          <Details />
        </div>
      </div>
      <Divider />
      <div className="flex justify-between items-center">
        <Button
          loading={loading}
          type="primary"
          icon={<SaveOutlined />}
          htmlType="submit"
        >
          Save
        </Button>
        <Popconfirm
          okText="Yes, delete"
          title="Delete category?"
          description="Category will be deleted, you won't be able to restore it."
          onConfirm={onDelete}
        >
          <Button
            disabled={loading}
            type="text"
            icon={<DeleteOutlined />}
            danger
          >
            Delete Category
          </Button>
        </Popconfirm>
      </div>
    </Card>
  );
}
