import { Select } from "antd";
import { ORDER_STATUS_LABELS } from "@utils/orders";

export default function Products(params) {
  const options = Object.entries(ORDER_STATUS_LABELS).map(([value, label]) => ({
    value,
    label,
  }));

  return (
    <Select
      mode="multiple"
      className="w-full"
      placeholder="Select Status"
      allowClear
      showSearch
      filterOption={(input, option) =>
        option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(v) => params.onChange("status", v)}
      options={options}
    />
  );
}
