import { Form, Select } from "antd";

export default function Shipping(props) {
  return (
    <Form.Item
      {...props}
      name="allow_shipping"
      className="w-full"
      label="Allow shipping"
      rules={[
        {
          required: true,
          message: "This field is required",
        },
      ]}
    >
      <Select
        placeholder="Select one"
        className="w-full"
        options={[
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ]}
      />
    </Form.Item>
  );
}
