import { useEffect, useState } from "react";
import {
  Image as AntImage,
  message,
  Button,
  Tooltip,
  Popconfirm,
  Divider,
} from "antd";
import {
  CloseOutlined,
  LoadingOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import { remove } from "ramda";
import { useDropzone } from "react-dropzone";
import config from "@config";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import * as API from "@api";

export default function Photos({ form }) {
  const [loading, setLoading] = useState(false);
  const [photos, setPhotos] = useState(
    form.getFieldValue(["meta", "images"]) || []
  );
  const { getRootProps, getInputProps } = useDropzone({
    disabled: loading || photos.length >= 10,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpeg", ".jpg"],
    },
    onDrop: async (files) => {
      // Ensure array
      if (!Array.isArray(files)) {
        files = [files];
      }

      if (files.length + photos.length > 10) {
        return message.error("Total number of images should not exceed 10");
      }

      files.map((file) => {
        if (file.size > 5 * 1000 * 1000) {
          return message.error(
            "Image size should not exceed 5MB, please try again with a smaller image"
          );
        }
      });

      try {
        setLoading(true);
        const _res = await API.Static.uploadImages({
          files,
        });

        if (!_res.data || _res.error) {
          throw new Error();
        }

        setLoading(false);
        setPhotos([...photos, ..._res.data.map((file) => file.name)]);
        return message.success("Images uploaded");
      } catch (error) {
        setLoading(false);
        return message.error(
          `Could not upload images, please try again later.`,
          2
        );
      }
    },
  });

  useEffect(() => {
    form.setFieldsValue({
      meta: {
        ...form.getFieldValue("meta"),
        images: photos,
      },
    });
  }, [photos]);

  const onSortEnd = (oldIndex, newIndex) => {
    setPhotos((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  };

  const onImageDelete = (idx) => {
    setPhotos(remove(idx, 1, photos));
  };

  return (
    <div>
      <Divider>Project Images</Divider>
      <span className="block w-full max-w-md m-auto text-center text-neutral-500 text-sm mb-2">
        Add up to 10 images for your project.
      </span>
      <SortableList
        onSortEnd={onSortEnd}
        className="flex flex-wrap select-none justify-start gap-2"
        draggedItemClassName="dragging-item"
      >
        {photos.map((item, key) => (
          <SortableItem key={item}>
            <div className="draggable-item">
              <AntImage
                width="100%"
                height="100%"
                src={`${config.api}/static/img/thumb-${item}`}
                preview={{
                  src: `${config.api}/static/img/${item}`,
                }}
                className="object-contain object-center"
              />
              <Popconfirm
                title="Delete image"
                okText="Yes"
                cancelText="Cancel"
                onConfirm={() => onImageDelete(key)}
              >
                <Tooltip placement="bottom" title="Delete Image">
                  <Button
                    size="small"
                    className="absolute top-2 right-2"
                    danger
                    type="primary"
                    shape="circle"
                    icon={<CloseOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            </div>
          </SortableItem>
        ))}
      </SortableList>

      <div
        className="ant-upload ant-upload-drag cursor-pointer hover:border-primary transition-long hover:transition-long bg-neutral-50 border-dashed border border-neutral-300 rounded-md my-5 py-8"
        {...getRootProps()}
      >
        <input {...getInputProps()} accept=".png, .jpg, .jpeg" />
        <p className="mx-auto block w-fit text-5xl mb-3 text-primary">
          {loading ? <LoadingOutlined /> : <PictureOutlined />}
        </p>
        <p className="text-sm text-black w-full block text-center">
          Click or drag file to this area to upload
        </p>
        <p className="text-xs text-neutral-500 w-full block text-center mt-2">
          Max file size: 5MB. Up to 10 images.
        </p>
      </div>
    </div>
  );
}
