import { Card, Button } from "antd";
import { Colours } from "@modules";
import { Breadcrumb } from "@components";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

export default function ListPage() {
  return (
    <div className="container max-content">
      <Breadcrumb data={[{ title: "Wine Colors", href: "/colors" }]} />
      <div className="flex justify-between items-center flex-wrap mb-5">
        <h1 className="text-3xl">Wine Colors</h1>
        <Link to="/colors/new">
          <Button className="m-0" icon={<PlusOutlined />} type="primary">
            Add New
          </Button>
        </Link>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Card bordered={false}>
            <Colours.List />
          </Card>
        </div>
      </div>
    </div>
  );
}
