import { Input } from "antd";

export default function Id({ onChange }) {
  return (
    <Input.Search
      placeholder="Search by ID"
      allowClear
      onSearch={(v) => onChange("id", v)}
      enterButton="Search"
    />
  );
}
