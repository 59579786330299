import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@components";
import { message, Form } from "antd";
import DataForm from "./form";
import * as API from "@api";

export default function EditData() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onSave = async (values) => {
    setLoading(true);

    API.Users.create(values)
      .then((res) => {
        if (!res || res?.error) {
          throw new Error(res?.message || "Error creating user");
        }

        return message.success("User created successfully", 1, () => {
          window.location.href = "/users";
        });
      })
      .catch((err) => message.error(err?.message || "Error creating user", 2))
      .finally(() => setLoading(false));
  };

  return (
    <Form
      form={form}
      onFinish={onSave}
      onFinishFailed={(err) =>
        err?.errorFields?.map((msg) => message.error(msg.errors[0], 2))
      }
      layout="vertical"
      className="w-full form-container"
    >
      <DataForm form={form} loading={loading} />
    </Form>
  );
}
