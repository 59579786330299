import { Form, InputNumber } from "antd";

export default function Alcohol(props) {
  return (
    <Form.Item {...props} name="alcohol" className="w-full" label="Alcohol %">
      <InputNumber
        controls={false}
        className="w-full"
        addonAfter="%"
        placeholder="Enter alcohol level"
        // decimal with one decimal place
        step={0.1}
      />
    </Form.Item>
  );
}
