import { Divider, Tabs } from "antd";
import { LANGUAGES } from "@utils/languages";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useState, useEffect } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function Editor({ form }) {
  const [locales, setLocales] = useState([]);

  useEffect(() => {
    LANGUAGES().then((res) => {
      setLocales(res.data.rows);
    });
  }, []);

  ClassicEditor.defaultConfig = {
    alignment: {
      options: ["left", "right"],
    },
    toolbar: [
      "undo",
      "redo",
      "|",
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "|",
      "alignment",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "indent",
      "outdent",
      "|",
      "blockQuote",
      "insertTable",
    ],
    image: {},
  };

  return (
    <div className="w-full">
      <Divider>Project Description</Divider>

      <Tabs
        items={locales?.map((locale) => ({
          key: locale.code,
          label: locale.name,
          children: (
            <CKEditor
              editor={ClassicEditor}
              data={form.getFieldValue(["meta", locale.code, "description"])}
              onChange={(_, editor) => {
                form.setFieldValue(
                  ["meta", locale.code, "description"],
                  editor.getData()
                );
              }}
            />
          ),
        }))}
      />
    </div>
  );
}
