import { Select } from "antd";
import { useEffect, useState } from "react";
import * as API from "@api";

export default function Varieties(params) {
  const [data, setData] = useState([]);

  useEffect(() => {
    API.Varieties.getAll({ limit: 1000 }).then((res) =>
      setData(
        res?.data?.rows?.map((colour) => ({
          label: colour?.name,
          value: colour?.id,
        })) || []
      )
    );
  }, []);

  return (
    <Select
      mode="multiple"
      className="w-full"
      placeholder="Select Variety"
      allowClear
      showSearch
      filterOption={(input, option) =>
        option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toLowerCase())
      }
      onChange={(v) => params.onChange("variety_id", v)}
      options={data}
    />
  );
}
