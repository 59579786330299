import { useEffect, useState } from "react";
import { Table, message, Tag, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { pathOr, includes } from "ramda";
import { Link } from "react-router-dom";
import config from "@config";
import * as API from "@api";
import * as Filters from "./filters";

export default function DataTable({
  showHeader = true,
  showFilters = ["search", "category", "product", "status", "dates"],
  defaultFilterValues = null,
  pageSize = 10,
  showPagination = true,
}) {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({
    id: pathOr(undefined, ["id"], defaultFilterValues),
    category_id: pathOr(undefined, ["category_id"], defaultFilterValues),
    language_id: pathOr(undefined, ["language_id"], defaultFilterValues),
    created_at: pathOr(undefined, ["created_at"], defaultFilterValues),
    product_id: pathOr(undefined, ["product_id"], defaultFilterValues),
    status: pathOr(undefined, ["status"], defaultFilterValues),
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
    showTotal: (total) => `Entries found: ${total}`,
  });

  useEffect(() => {
    getTableData();
  }, [filter, search]);

  async function getTableData(params = pagination, _, sorter) {
    setLoading(true);
    const _res = await API.Articles.getAll({
      s: search,
      filter,
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
      order: sorter?.field && {
        [sorter?.field]: sorter?.order === "ascend" ? "ASC" : "DESC",
      },
    });

    if (!_res.data) {
      return message.error("Error while getting data, please try again later");
    }

    setTableData(_res.data.rows);
    setPagination({
      ...params,
      total: _res.data.count,
      showTotal: (total) => `Entries found: ${total}`,
    });
    setLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter(
      { ...filter, [f]: v || undefined },
      setPagination({ ...pagination, current: 1 })
    );
  }

  function onSearch(v) {
    setSearch(v, setPagination({ ...pagination, current: 1 }));
  }

  const columns = [
    {
      dataIndex: "image",
      key: "image",
      align: "center",
      width: 80,
      render: (data) => (
        <img
          loading="lazy"
          src={
            data ? `${config.api}/static/img/thumb-${data}` : "/img/no-img.png"
          }
          alt="Article"
          className="block mx-auto w-20 h-20 object-contain rounded-sm bg-white"
        />
      ),
    },
    {
      dataIndex: "info",
      key: "info",
      align: "left",
      title: "Article",
      render: (_, data) => (
        <div>
          <span className="block text-base font-medium text-black">
            {data?.title}
          </span>
          {data?.subtitle && (
            <span className="block text-sm font-normal text-neutral-500 mt-1">
              {data.subtitle}
            </span>
          )}
        </div>
      ),
    },
    {
      dataIndex: "category_id",
      key: "category_id",
      align: "center",
      title: "Category",
      render: (_, data) =>
        data?.category?.name && (
          <Tag className="m-0">{data?.category?.name}</Tag>
        ),
      sorter: (a, b) => a?.category_id - b?.category_id,
    },
    {
      dataIndex: "tags",
      key: "tags",
      align: "center",
      title: "Tags",
      width: 200,
      render: (_, data) =>
        data?.tags &&
        data?.tags?.map((tag) => (
          <span className="block text-sm font-normal text-neutral-500">
            {tag?.name}
          </span>
        )),
    },
    {
      dataIndex: "status",
      key: "status",
      align: "center",
      title: "Status",
      render: (data) => (
        <Tag className="m-0" color={data === "published" ? "green" : "default"}>
          {data === "published" ? "Published" : "Draft"}
        </Tag>
      ),
      sorter: (a, b) => a?.status.localeCompare(b?.status),
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (_, all) => (
        <Link to={`/articles/edit/${all.id}`}>
          <Button icon={<EditOutlined />}>Edit</Button>
        </Link>
      ),
    },
  ];

  return (
    <div className="grid grid-cols-12 gap-4">
      {includes("search", showFilters) && (
        <div className="col-span-12 lg:col-span-3">
          <Filters.Search onSearch={onSearch} />
        </div>
      )}
      {includes("category", showFilters) && (
        <div className="col-span-12 lg:col-span-3">
          <Filters.Category onChange={onFilterChange} />
        </div>
      )}
      {includes("status", showFilters) && (
        <div className="col-span-12 lg:col-span-3">
          <Filters.Status onChange={onFilterChange} />
        </div>
      )}
      {includes("dates", showFilters) && (
        <div className="col-span-12 lg:col-span-3">
          <Filters.Dates onChange={onFilterChange} />
        </div>
      )}
      <div className="col-span-12">
        <Table
          showHeader={showHeader}
          onChange={getTableData}
          pagination={showPagination && pagination}
          dataSource={tableData}
          loading={loading}
          className="rounded-sm overflow-hidden w-full"
          rowKey="id"
          scroll={{ x: "max-content" }}
          columns={columns}
        />
      </div>
    </div>
  );
}
