import axios from "axios";
import config from "@config";

const FRONTEND_API = `${config.frontend}/api/revalidate`;
const secret = config.revalidateSecret;

export const staticPages = (params = {}) =>
  axios
    .get(`${FRONTEND_API}`, {
      params: { secret, ...params },
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));

export const articles = (params = {}) =>
  axios
    .get(`${FRONTEND_API}/articles`, {
      params: { secret, ...params },
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));

export const articleCategories = (params = {}) =>
  axios
    .get(`${FRONTEND_API}/article_categories`, {
      params: { secret, ...params },
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));

export const wines = (params = {}) =>
  axios
    .get(`${FRONTEND_API}/wines`, {
      params: { secret, ...params },
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));

export const allWines = (params = {}) =>
  axios
    .get(`${FRONTEND_API}/all_wines`, {
      params: { secret, ...params },
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));
