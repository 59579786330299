import { Alert } from "antd";
import Name from "./name";
import Email from "./email";

export default function Details() {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <Name />
      </div>
      <div className="col-span-12">
        <Email />
      </div>
      <div className="col-span-12">
        <Alert
          description="A password will be generated and sent to the user's email address."
          type="warning"
          showIcon
        />
      </div>
    </div>
  );
}
