import { useState, useEffect } from "react";
import { CopyOutlined } from "@ant-design/icons";
import { Loading } from "@components";
import { message, Button } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import List from "./list";
import * as API from "@api";

export default function Content({ type = "page:home", lang = null }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [widgets, setWidgets] = useState([]);

  useEffect(() => {
    Promise.all([
      API.Settings.getOne({ type }),
      API.Settings.getAll({ s: "widget:", limit: 100 }),
    ])
      .then(([pageData, widgetsData]) => {
        if (!pageData || !widgetsData) {
          throw Error("Can't load data, try again later");
        }

        setData(pageData?.data?.data || null);
        setWidgets(widgetsData?.data?.rows || []);
        setLoading(false);
      })
      .catch((err) => message.error(err.message || "Error loading data", 2));
  }, []);

  if (!data) {
    return <Loading compact />;
  }

  const onSave = async () => {
    setLoading(true);
    API.Settings.update({
      type,
      data: {
        ...data,
        [lang]: {
          ...data[lang],
          widgets: [...data?.[lang]?.widgets],
        },
      },
    })
      .then(async (res) => {
        if (res?.error || !res?.data) {
          throw new Error(res?.message || "Error updating Page");
        }

        await API.Revalidate.staticPages();

        return message.success("Page updated successfully", 1);
      })
      .catch((err) => message.error(err.message || "Error updating Page", 2))
      .finally(() => setLoading(false));
  };

  return (
    <div>
      {lang !== "en" &&
        data?.en?.widgets?.length > 0 &&
        data?.[lang]?.widgets?.length === 0 && (
          <div className="w-full flex justify-center items-center my-3">
            <Button
              onClick={() => {
                setData({
                  ...data,
                  [lang]: {
                    ...data[lang],
                    widgets: data?.en?.widgets,
                  },
                });
              }}
              type="link"
              icon={<CopyOutlined />}
            >
              Copy content from English Version
            </Button>
          </div>
        )}
      <List
        data={data}
        lang={lang}
        onChange={setData}
        widgets={widgets}
        loading={loading}
      />
      <Button
        className="mt-5"
        loading={loading}
        type="primary"
        icon={<SaveOutlined />}
        onClick={onSave}
      >
        Save
      </Button>
    </div>
  );
}
