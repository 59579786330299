import { Loading } from "@components";
import { Descriptions, message, Tag, Button, Select } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { orderStatuses } from "@utils/orders";
import { ORDER_STATUS_LABELS } from "@utils/orders";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Products from "./products";
import classNames from "classnames";
import dayjs from "dayjs";
import * as API from "@api";

const { Item } = Descriptions;

export default function Order({ className, ...props }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    API.Orders.getOne({ id: props?.orderId })
      .then(async (res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch(() => {
        message.error("Error while getting data, please try again later", 2);
        setLoading(false);
      });
  }, []);

  if (loading || !data) {
    return <Loading compact />;
  }

  const status = orderStatuses(data?.status);

  return (
    <div key={props?.orderId} className={classNames("w-full", className)}>
      <Descriptions
        extra={
          <Link to={`/orders/edit/${data?.id}`}>
            <Button icon={<EditOutlined />}>Edit Order</Button>
          </Link>
        }
        size="small"
        layout="vertical"
        column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
        title="Order Info"
        bordered
        colon={false}
      >
        <Item label="ID">
          <Tag>{data?.id}</Tag>
        </Item>
        <Item label="Customer">{data?.name || "N/a"}</Item>
        <Item label="Status">
          <Tag color={status.tagColor}>{status.name}</Tag>{" "}
          <Link to={`/orders/edit/${data?.id}`}>Change</Link>
        </Item>
        <Item label="Customer email">{data?.email || "N/a"}</Item>
        <Item label="Customer phone">{data?.phone || "N/a"}</Item>
        <Item label="Shipping address">
          {data?.country?.name || "Country not specified"},{" "}
          {data?.address || "N/a"}
        </Item>
        <Item span={3} label="Products">
          <Products data={data?.products} />
        </Item>
        <Item label="Order placed">
          {dayjs(data?.created_at).format("HH:mm · DD MMM YYYY")}
        </Item>
        <Item label="Last updated">
          {dayjs(data?.updated_at).format("HH:mm · DD MMM YYYY")}
        </Item>
      </Descriptions>
    </div>
  );
}
