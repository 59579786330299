import { Statistic, message } from "antd";
import { Loading } from "@components";
import { useState, useEffect } from "react";
import * as API from "@api";

export default function StatsCard({
  className,
  title = "Blog Posts",
  filter = { status: "published" },
}) {
  const [values, setValues] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    API.Articles.getAll({
      filter,
      limit: 1,
    })
      .then((res) => {
        setValues(res?.data?.count || 0);
        setLoading(false);
      })
      .catch(() => message.error("Error loading data. Try again later.", 2));
  }, []);

  if (loading) return <Loading compact />;

  return <Statistic className={className} title={title} value={values} />;
}
