import { message, Select, Spin, Form } from "antd";
import { useEffect, useState } from "react";
import debounce from "debounce";
import * as API from "@api";

export default function Tags(props) {
  const [fetching, setFetching] = useState(false);
  const defaultOptions = props.form.getFieldValue("tags") || [];
  const [options, setOptions] = useState([]);

  useEffect(() => {
    API.Tags.getAll({
      filter: { id: defaultOptions },
      limit: 100,
    }).then((res) => {
      if (res?.error || !res) {
        return;
      }

      setOptions(
        res?.data?.rows?.map((res) => ({
          value: res.id,
          label: res.name,
        }))
      );
    });
  }, []);

  let fetchData = async (value) => {
    setOptions([]);
    setFetching(true);
    const data = await API.Tags.getAll({
      limit: 10,
      s: value,
    });

    if (!data || data?.error) {
      return message.error(
        "Error while getting data, please try again later",
        3
      );
    }

    setOptions(
      data?.data?.rows?.map((res) => ({
        value: res.id,
        label: res.name,
      }))
    );

    setFetching(false);
  };

  fetchData = debounce(fetchData, 200);

  return (
    <Form.Item name="tags">
      <Select
        mode="tags"
        {...props}
        showSearch
        className="w-full"
        allowClear
        placeholder="Start typing to search for tags"
        filterOption={false}
        onSearch={fetchData}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        options={options}
      />
    </Form.Item>
  );
}
