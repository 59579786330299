import { Form, Select } from "antd";
import { ORDER_STATUS_LABELS } from "@utils/orders";

export default function DataFormItem(props) {
  return (
    <Form.Item
      {...props}
      name="status"
      className="w-full"
      label="Status"
      rules={[
        {
          required: true,
          message: "Status is required",
        },
      ]}
    >
      <Select
        placeholder="Select one"
        className="w-full"
        options={Object.keys(ORDER_STATUS_LABELS).map((key) => ({
          label: ORDER_STATUS_LABELS[key],
          value: key,
        }))}
      />
    </Form.Item>
  );
}
