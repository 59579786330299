import { Divider, Form, Input, Switch } from "antd";
import {
  MailOutlined,
  PhoneOutlined,
  InstagramOutlined,
  FacebookOutlined,
  TwitterOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";

export default function Details({ form, ...props }) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <Form.Item
          {...props}
          name="phone"
          className="w-full"
          label="Phone No."
          rules={[
            {
              required: true,
              message: "Phone is required",
            },
          ]}
        >
          <Input
            addonBefore={<PhoneOutlined />}
            className="w-full"
            placeholder="Enter phone no."
          />
        </Form.Item>
      </div>
      <div className="col-span-12">
        <Form.Item {...props} name="email" className="w-full" label="Email">
          <Input
            addonBefore={<MailOutlined />}
            type="email"
            className="w-full"
            placeholder="Enter email"
          />
        </Form.Item>
      </div>
      <div className="col-span-12">
        <Form.Item {...props} name="address" className="w-full" label="Address">
          <Input.TextArea
            rows={3}
            className="w-full"
            placeholder="Enter company address"
          />
        </Form.Item>
      </div>
      <div className="col-span-12">
        <Form.Item
          {...props}
          name="instagram"
          className="w-full"
          label="Instagram URL"
        >
          <Input
            addonBefore={<InstagramOutlined />}
            className="w-full"
            placeholder="Enter Instagram URL"
          />
        </Form.Item>
      </div>
      <div className="col-span-12">
        <Form.Item
          {...props}
          name="facebook"
          className="w-full"
          label="Facebook URL"
        >
          <Input
            addonBefore={<FacebookOutlined />}
            className="w-full"
            placeholder="Enter Facebook URL"
          />
        </Form.Item>
      </div>
      <div className="col-span-12">
        <Form.Item
          {...props}
          name="twitter"
          className="w-full"
          label="Twitter URL"
        >
          <Input
            addonBefore={<TwitterOutlined />}
            className="w-full"
            placeholder="Enter Twitter URL"
          />
        </Form.Item>
      </div>
      <div className="col-span-12">
        <Form.Item
          {...props}
          name="whatsapp"
          className="w-full"
          label="WhatsApp No."
        >
          <Input
            addonBefore={<WhatsAppOutlined />}
            className="w-full"
            placeholder="Enter WhatsApp No. (e.g. +7701...)"
          />
        </Form.Item>
      </div>
      <div className="col-span-12">
        <Divider className="my-2" orientation="left">
          Order to Email forwards
        </Divider>
      </div>
      <div className="col-span-12">
        <Form.Item
          {...props}
          name="order_email_forward"
          className="w-full"
          label="Send email to this address when order is placed"
        >
          <Input className="w-full" placeholder="Enter your email" />
        </Form.Item>
      </div>
      <div className="col-span-12">
        <Divider className="my-2" orientation="left">
          Bitrix24 Integration
        </Divider>
      </div>
      <div className="col-span-12">
        <Form.Item
          {...props}
          name="allow_bitrix"
          className="w-full"
          label="Send leads to Bitrix"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </div>
      <div className="col-span-12">
        <Divider className="my-2" orientation="left">
          Bottom EU Banner
        </Divider>
      </div>
      <div className="col-span-12">
        <Form.Item
          {...props}
          name="show_banner"
          className="w-full"
          label="Show EU Banner"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </div>
      <div className="col-span-12">
        <Form.Item
          {...props}
          name="banner_text"
          className="w-full"
          label="EU Banner Text"
        >
          <Input.TextArea
            rows={3}
            className="w-full"
            placeholder="Enter EU Banner text"
          />
        </Form.Item>
      </div>
      <div className="col-span-12">
        <Form.Item
          {...props}
          name="banner_text_logo"
          className="w-full"
          label="EU Banner Logo Text"
        >
          <Input className="w-full" placeholder="Enter EU Banner logo text" />
        </Form.Item>
      </div>
      <div className="col-span-12">
        <Divider className="my-2" orientation="left">
          Additional Script in Contacts
        </Divider>
      </div>
      <div className="col-span-12">
        <Form.Item
          {...props}
          name="under_contacts_script"
          className="w-full"
          label="Maps (Script) code under Contacts details"
          help="Insert your maps script code here. E.g. go to https://www.google.com/maps/d/u/0/ -> Select your Map, Click on Add to Website, Copy the code and paste here."
        >
          <Input.TextArea
            rows={3}
            className="w-full"
            placeholder="Enter code "
          />
        </Form.Item>
      </div>
    </div>
  );
}
