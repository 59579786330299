import * as Pages from "@pages";
import Layout from "@modules/layouts/admin";

const routes = [
  {
    path: "/settings/global",
    element: (
      <Layout page="page-global">
        <Pages.Settings.Global />
      </Layout>
    ),
  },
  {
    path: "/settings/about",
    element: (
      <Layout page="page-about">
        <Pages.Settings.About />
      </Layout>
    ),
  },
  {
    path: "/settings/delivery",
    element: (
      <Layout page="page-delivery">
        <Pages.Settings.Delivery />
      </Layout>
    ),
  },
  {
    path: "/settings/contacts",
    element: (
      <Layout page="page-contacts">
        <Pages.Settings.Contacts />
      </Layout>
    ),
  },
  {
    path: "/settings/wines",
    element: (
      <Layout page="page-wines">
        <Pages.Settings.Wines />
      </Layout>
    ),
  },
  {
    path: "/settings/blog",
    element: (
      <Layout page="page-blog">
        <Pages.Settings.Blog />
      </Layout>
    ),
  },
  {
    path: "/settings/homepage",
    element: (
      <Layout page="page-homepage">
        <Pages.Settings.Homepage />
      </Layout>
    ),
  },
  {
    path: "/settings",
    element: (
      <Layout page="page-homepage">
        <Pages.Settings.Homepage />
      </Layout>
    ),
  },
  {
    path: "/users/view/:id",
    element: (
      <Layout page="users">
        <Pages.Users.View />
      </Layout>
    ),
  },
  {
    path: "/users/edit/:id",
    element: (
      <Layout page="users">
        <Pages.Users.Edit />
      </Layout>
    ),
  },
  {
    path: "/users/new",
    element: (
      <Layout page="users">
        <Pages.Users.New />
      </Layout>
    ),
  },
  {
    path: "/users",
    element: (
      <Layout page="users">
        <Pages.Users.List />
      </Layout>
    ),
  },
  {
    path: "/tags/edit/:id",
    element: (
      <Layout page="tags">
        <Pages.Tags.Edit />
      </Layout>
    ),
  },
  {
    path: "/tags/new",
    element: (
      <Layout page="tags">
        <Pages.Tags.New />
      </Layout>
    ),
  },
  {
    path: "/tags",
    element: (
      <Layout page="tags">
        <Pages.Tags.List />
      </Layout>
    ),
  },
  {
    path: "/article_categories/edit/:id",
    element: (
      <Layout page="article_categories">
        <Pages.ArticleCategories.Edit />
      </Layout>
    ),
  },
  {
    path: "/article_categories/new",
    element: (
      <Layout page="article_categories">
        <Pages.ArticleCategories.New />
      </Layout>
    ),
  },
  {
    path: "/article_categories",
    element: (
      <Layout page="article_categories">
        <Pages.ArticleCategories.List />
      </Layout>
    ),
  },
  {
    path: "/articles/edit/:id",
    element: (
      <Layout page="articles">
        <Pages.Articles.Edit />
      </Layout>
    ),
  },
  {
    path: "/articles/new",
    element: (
      <Layout page="articles">
        <Pages.Articles.New />
      </Layout>
    ),
  },
  {
    path: "/articles",
    element: (
      <Layout page="articles">
        <Pages.Articles.List />
      </Layout>
    ),
  },
  {
    path: "/varieties/edit/:id",
    element: (
      <Layout page="varieties">
        <Pages.Varieties.Edit />
      </Layout>
    ),
  },
  {
    path: "/varieties/new",
    element: (
      <Layout page="varieties">
        <Pages.Varieties.New />
      </Layout>
    ),
  },
  {
    path: "/varieties/view/:id",
    element: (
      <Layout page="varieties">
        <Pages.Varieties.View />
      </Layout>
    ),
  },
  {
    path: "/varieties",
    element: (
      <Layout page="varieties">
        <Pages.Varieties.List />
      </Layout>
    ),
  },
  {
    path: "/colors/edit/:id",
    element: (
      <Layout page="colors">
        <Pages.Colours.Edit />
      </Layout>
    ),
  },
  {
    path: "/colors/new",
    element: (
      <Layout page="colors">
        <Pages.Colours.New />
      </Layout>
    ),
  },
  {
    path: "/colors/view/:id",
    element: (
      <Layout page="colors">
        <Pages.Colours.View />
      </Layout>
    ),
  },
  {
    path: "/colors",
    element: (
      <Layout page="colors">
        <Pages.Colours.List />
      </Layout>
    ),
  },
  {
    path: "/sweetnesses/edit/:id",
    element: (
      <Layout page="sweetnesses">
        <Pages.Sweetnesses.Edit />
      </Layout>
    ),
  },
  {
    path: "/sweetnesses/new",
    element: (
      <Layout page="sweetnesses">
        <Pages.Sweetnesses.New />
      </Layout>
    ),
  },
  {
    path: "/sweetnesses/view/:id",
    element: (
      <Layout page="sweetnesses">
        <Pages.Sweetnesses.View />
      </Layout>
    ),
  },
  {
    path: "/sweetnesses",
    element: (
      <Layout page="sweetnesses">
        <Pages.Sweetnesses.List />
      </Layout>
    ),
  },
  {
    path: "/producers/edit/:id",
    element: (
      <Layout page="producers">
        <Pages.Producers.Edit />
      </Layout>
    ),
  },
  {
    path: "/producers/new",
    element: (
      <Layout page="producers">
        <Pages.Producers.New />
      </Layout>
    ),
  },
  {
    path: "/producers/view/:id",
    element: (
      <Layout page="producers">
        <Pages.Producers.View />
      </Layout>
    ),
  },
  {
    path: "/producers",
    element: (
      <Layout page="producers">
        <Pages.Producers.List />
      </Layout>
    ),
  },
  {
    path: "/regions/edit/:id",
    element: (
      <Layout page="regions">
        <Pages.Regions.Edit />
      </Layout>
    ),
  },
  {
    path: "/regions/new",
    element: (
      <Layout page="regions">
        <Pages.Regions.New />
      </Layout>
    ),
  },
  {
    path: "/regions/view/:id",
    element: (
      <Layout page="regions">
        <Pages.Regions.View />
      </Layout>
    ),
  },
  {
    path: "/regions",
    element: (
      <Layout page="regions">
        <Pages.Regions.List />
      </Layout>
    ),
  },
  {
    path: "/countries/edit/:id",
    element: (
      <Layout page="countries">
        <Pages.Countries.Edit />
      </Layout>
    ),
  },
  {
    path: "/countries/new",
    element: (
      <Layout page="countries">
        <Pages.Countries.New />
      </Layout>
    ),
  },
  {
    path: "/countries/view/:id",
    element: (
      <Layout page="countries">
        <Pages.Countries.View />
      </Layout>
    ),
  },
  {
    path: "/countries",
    element: (
      <Layout page="countries">
        <Pages.Countries.List />
      </Layout>
    ),
  },
  {
    path: "/projects/edit/:id",
    element: (
      <Layout page="projects">
        <Pages.Projects.Edit />
      </Layout>
    ),
  },
  {
    path: "/projects/new",
    element: (
      <Layout page="projects">
        <Pages.Projects.New />
      </Layout>
    ),
  },
  {
    path: "/projects/view/:id",
    element: (
      <Layout page="projects">
        <Pages.Projects.View />
      </Layout>
    ),
  },
  {
    path: "/projects",
    element: (
      <Layout page="projects">
        <Pages.Projects.List />
      </Layout>
    ),
  },
  {
    path: "/products/edit/:id",
    element: (
      <Layout page="products">
        <Pages.Products.Edit />
      </Layout>
    ),
  },
  {
    path: "/products/new",
    element: (
      <Layout page="products">
        <Pages.Products.New />
      </Layout>
    ),
  },
  {
    path: "/products/view/:id",
    element: (
      <Layout page="products">
        <Pages.Products.View />
      </Layout>
    ),
  },
  {
    path: "/products",
    element: (
      <Layout page="products">
        <Pages.Products.List />
      </Layout>
    ),
  },
  {
    path: "/orders/edit/:id",
    element: (
      <Layout page="orders">
        <Pages.Orders.Edit />
      </Layout>
    ),
  },
  {
    path: "/orders/view/:id",
    element: (
      <Layout page="orders">
        <Pages.Orders.View />
      </Layout>
    ),
  },
  {
    path: "/orders",
    element: (
      <Layout page="orders">
        <Pages.Orders.List />
      </Layout>
    ),
  },
  {
    path: "/",
    element: (
      <Layout page="dashboard">
        <Pages.Dashboard />
      </Layout>
    ),
  },
];

export default routes;
