import { Form, Input } from "antd";

export default function Alcohol(props) {
  return (
    <Form.Item
      {...props}
      name="name"
      className="w-full"
      label="Name"
      rules={[
        {
          required: true,
          message: "Name is required",
        },
      ]}
    >
      <Input
        controls={false}
        minLength={3}
        className="w-full"
        placeholder="Enter name"
      />
    </Form.Item>
  );
}
