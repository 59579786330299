import { Settings } from "@modules";
import { Breadcrumb } from "@components";

export default function IndexPage() {
  return (
    <div className="container max-content">
      <Breadcrumb
        data={[{ title: "Wines Page Settings", href: "/settings/wines" }]}
      />
      <div className="flex justify-between items-center flex-wrap mb-5">
        <h1 className="text-3xl">Wines Page Settings</h1>
      </div>
      <Settings type="page:wines" />
    </div>
  );
}
