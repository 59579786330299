import { Form, Input } from "antd";

export default function DataFormItem(props) {
  return (
    <Form.Item
      {...props}
      name="name"
      className="w-full"
      label="Name"
      rules={[
        {
          required: true,
          message: "Name is required",
        },
      ]}
    >
      <Input className="w-full" placeholder="Enter category name" />
    </Form.Item>
  );
}
