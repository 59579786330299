import { Form, InputNumber } from "antd";

export default function Alcohol(props) {
  return (
    <Form.Item
      {...props}
      name="price"
      className="w-full"
      label="Price"
      rules={[
        {
          required: true,
          message: "Price amount is required",
        },
      ]}
    >
      <InputNumber
        min={0}
        controls={false}
        className="w-full"
        decimalSeparator="."
        placeholder="Enter price amount, e.g. 89.99"
      />
    </Form.Item>
  );
}
