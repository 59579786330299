import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Loading } from "@components";
import { useAuth } from "@contexts";

export default function LogoutPage() {
  const [loading, setLoading] = useState(true);
  const { currentUser, logout } = useAuth();

  useEffect(() => {
    logout().then(() => setLoading(false));
  }, []);

  if (!currentUser && !loading) {
    return <Navigate replace to="/login" />;
  }

  return <Loading />;
}
