import { Divider, Tabs } from "antd";
import { LANGUAGES } from "@utils/languages";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useState, useEffect } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function Editor({ form }) {
  const [locales, setLocales] = useState([]);

  useEffect(() => {
    LANGUAGES().then((res) => {
      setLocales(res.data.rows);
    });
  }, []);

  return (
    <div className="w-full">
      <Divider>Product Description</Divider>

      <Tabs
        items={locales?.map((locale) => ({
          key: locale.code,
          label: locale.name,
          children: (
            <CKEditor
              config={{
                toolbar: [
                  "undo",
                  "redo",
                  "|",
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "indent",
                  "outdent",
                  "|",
                  "blockQuote",
                  "insertTable",
                ],
                image: {},
              }}
              editor={ClassicEditor}
              data={
                locale?.meta?.is_default
                  ? form.getFieldValue("description")
                  : form.getFieldValue(["meta", locale.code, "description"])
              }
              onChange={(_, editor) => {
                if (locale?.meta?.is_default) {
                  form.setFieldValue("description", editor.getData());
                }

                form.setFieldValue(
                  ["meta", locale.code, "description"],
                  editor.getData()
                );
              }}
            />
          ),
        }))}
      />
    </div>
  );
}
