import { Loading } from "@components";
import { Descriptions, message, Tag, Button, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import dayjs from "dayjs";
import ProductList from "@modules/products/list";
import * as API from "@api";

const { Item } = Descriptions;

export default function Project({ className, ...props }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    API.Projects.getOne({ id: props?.projectId })
      .then(async (res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch(() => {
        message.error("Error while getting data, please try again later", 2);
        setLoading(false);
      });
  }, []);

  if (loading || !data) {
    return <Loading compact />;
  }

  return (
    <div key={props?.productId} className={classNames("w-full", className)}>
      <Descriptions
        extra={
          <Link to={`/projects/edit/${data?.id}`}>
            <Button icon={<EditOutlined />}>Edit Project</Button>
          </Link>
        }
        size="small"
        layout="vertical"
        column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
        title="Project Info"
        bordered
        colon={false}
      >
        <Item label="ID">{data?.id && <Tag>{data?.id}</Tag>}</Item>
        <Item span={2} label="Name">
          {data?.name}
        </Item>
        <Item span={3} label="Project description">
          <div dangerouslySetInnerHTML={{ __html: data?.description }} />
        </Item>
        <Item label="Project created">
          {dayjs(data?.created_at).format("HH:mm · DD MMM YYYY")}
        </Item>
        <Item label="Last updated" span={3}>
          {dayjs(data?.updated_at).format("HH:mm · DD MMM YYYY")}
        </Item>
      </Descriptions>
      <Divider className="my-10">Products</Divider>
      {data && (
        <ProductList
          showFilters={[]}
          defaultFilterValues={{ project_id: data?.id }}
        />
      )}
    </div>
  );
}
