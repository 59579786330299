import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function Editor({ input, onChange, key, data }) {
  return (
    <div className="col-span-12">
      <span className="block text-xs font-medium text-neutral-800 mb-2">
        {input.label}{" "}
        {input.required && <i className="not-italic text-rose-600">*</i>}
      </span>
      <CKEditor
        config={{
          toolbar: [
            "undo",
            "redo",
            "|",
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "indent",
            "outdent",
            "|",
            "blockQuote",
            "insertTable",
          ],
          image: {},
        }}
        editor={ClassicEditor}
        data={data?.data?.[input.key] || ""}
        onChange={(_, editor) => onChange(input.key, editor.getData())}
      />
    </div>
  );
}
