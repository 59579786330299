import { useEffect, useState } from "react";
import { Loading } from "@components";
import { message } from "antd";
import { Navigate } from "react-router-dom";
import * as API from "@api";

export default function NewPage() {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!data) {
      API.Countries.create({
        name: `New Country`,
        allow_shipping: false,
      })
        .then((res) => {
          if (!res || res?.error) {
            throw new Error("Error while creating country");
          }

          setData(res?.data);
        })
        .catch((err) => {
          return message.error(
            err?.message || "Error while creating country",
            2
          );
        });
    }
  }, [data]);

  if (data?.id) {
    return <Navigate to={`/countries/edit/${data?.id}`} />;
  }

  return <Loading />;
}
