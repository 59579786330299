import { Divider, Card, Button } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Details from "./details";

export default function EditEntry({ data, loading, form }) {
  return (
    <Card bordered={false}>
      <div className="grid grid-cols-12 gap-x-4 gap-y-2">
        <div className="col-span-12">
          <Details form={form} />
        </div>
      </div>
      <Divider />
      <Button
        loading={loading}
        type="primary"
        icon={<SaveOutlined />}
        htmlType="submit"
      >
        Save
      </Button>
    </Card>
  );
}
