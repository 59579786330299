import Name from "./name";
import Status from "./status";
import Email from "./email";
import Phone from "./phone";
import Country from "./country";
import Address from "./address";
import Products from "./products";

export default function Details({ form }) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-3">
        <Status />
      </div>
      <div className="col-span-12 md:col-span-3">
        <Name />
      </div>
      <div className="col-span-12 md:col-span-3">
        <Email />
      </div>
      <div className="col-span-12 md:col-span-3">
        <Phone />
      </div>
      <div className="col-span-12 md:col-span-12">
        <Country />
      </div>
      <div className="col-span-12 md:col-span-12">
        <Address />
      </div>
      <div className="col-span-12 md:col-span-12">
        <Products form={form} />
      </div>
    </div>
  );
}
