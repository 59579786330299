import { Form, Input } from "antd";

export default function Password(props) {
  return (
    <div>
      <Form.Item
        {...props}
        name="new_password"
        className="w-full"
        label="New Password"
      >
        <Input.Password
          autoComplete="off"
          className="w-full"
          placeholder="Enter new password for user"
        />
      </Form.Item>
      <Form.Item
        {...props}
        name="confirm_password"
        className="w-full"
        label="Confirm New Password"
      >
        <Input.Password
          autoComplete="off"
          className="w-full"
          placeholder="Confirm new password"
        />
      </Form.Item>
    </div>
  );
}
