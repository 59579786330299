import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function Editor({ form }) {
  return (
    <div className="w-full">
      <CKEditor
        config={{
          toolbar: [
            "undo",
            "redo",
            "|",
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "indent",
            "outdent",
            "|",
            "blockQuote",
            "insertTable",
          ],
          image: {},
        }}
        editor={ClassicEditor}
        data={form.getFieldValue(["content", "data"])}
        onChange={(_, editor) => {
          form.setFieldValue(["content", "data"], editor.getData());
        }}
      />
    </div>
  );
}
