import { Form, Input, Switch } from "antd";

export default function Details({ form, type, ...props }) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <Form.Item
          {...props}
          name={["meta", "title"]}
          className="w-full"
          label="Page Title"
          rules={[
            {
              required: true,
              message: "Title is required",
            },
          ]}
        >
          <Input className="w-full" placeholder="Enter page title" />
        </Form.Item>
      </div>
      <div className="col-span-12">
        <Form.Item
          {...props}
          name={["meta", "description"]}
          className="w-full"
          label="Meta Description"
          rules={[
            {
              required: true,
              message: "Meta description is required",
            },
          ]}
        >
          <Input.TextArea
            rows={3}
            className="w-full"
            placeholder="Enter description"
          />
        </Form.Item>
      </div>
      {type === "page:home" && (
        <div className="col-span-12">
          <Form.Item
            {...props}
            name={["meta", "ig_widget"]}
            className="w-full"
            label="Instagram Widget Code"
            rules={[
              {
                required: false,
              },
            ]}
            help="Get widget code snippet from https://snapwidget.com/"
          >
            <Input.TextArea
              rows={3}
              className="w-full"
              placeholder="Enter code snippet here"
            />
          </Form.Item>
        </div>
      )}
      <div className="col-span-12">
        <Form.Item
          {...props}
          name={["meta", "show_nav"]}
          className="w-full"
          label="Show link in Navigation"
          valuePropName="checked"
          tooltip="Shows link to this page in Top and Bottom site navigation"
        >
          <Switch />
        </Form.Item>
      </div>
    </div>
  );
}
