import Name from "./name";
import Shipping from "./shipping";

export default function Details() {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <Name />
      </div>
      <div className="col-span-12">
        <Shipping />
      </div>
    </div>
  );
}
