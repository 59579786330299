import { Switch } from "antd";

export default function Widget({ option, onChange, data }) {
  return (
    <div className="col-span-12 flex justify-between items-center">
      <span className="block text-xs font-medium text-neutral-800 m-0 leading-none">
        {option.data.label}{" "}
        {option.data.required && <i className="not-italic text-rose-600">*</i>}
      </span>
      <Switch
        checked={data?.options?.[option.key] || false}
        onChange={(val) => {
          onChange(option.key, val);
        }}
        key={option.key}
        title={option.data.label}
      />
    </div>
  );
}
