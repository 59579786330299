import { useEffect, useState } from "react";
import { Table, message, Tag, Modal, Dropdown } from "antd";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import { pathOr, includes } from "ramda";
import { useNavigate } from "react-router-dom";
import View from "../view";
import config from "@config";
import * as API from "@api";
import * as Filters from "./filters";

export default function DataTable({
  showHeader = true,
  showFilters = [
    "search",
    "id",
    "sku",
    "colour",
    "variety",
    "sweetness",
    "producer",
    "region",
    "status",
    "dates",
    "project",
  ],
  defaultFilterValues = null,
  pageSize = 10,
  showPagination = true,
}) {
  const navigate = useNavigate();
  const [productId, setProductId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({
    id: pathOr(undefined, ["id"], defaultFilterValues),
    sku: pathOr(undefined, ["sku"], defaultFilterValues),
    created_at: pathOr(undefined, ["created_at"], defaultFilterValues),
    type_id: pathOr(undefined, ["type_id"], defaultFilterValues),
    colour_id: pathOr(undefined, ["colour_id"], defaultFilterValues),
    variety_id: pathOr(undefined, ["variety_id"], defaultFilterValues),
    sweetness_id: pathOr(undefined, ["sweetness_id"], defaultFilterValues),
    producer_id: pathOr(undefined, ["producer_id"], defaultFilterValues),
    project_id: pathOr(undefined, ["project_id"], defaultFilterValues),
    region_id: pathOr(undefined, ["region_id"], defaultFilterValues),
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
    showTotal: (total) => `Entries found: ${total}`,
  });

  useEffect(() => {
    getTableData();
  }, [filter, search]);

  async function getTableData(params = pagination, _, sorter) {
    setLoading(true);
    const _res = await API.Products.getAll({
      s: search,
      filter,
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
      order: sorter?.field && {
        [sorter?.field]: sorter?.order === "ascend" ? "ASC" : "DESC",
      },
    });

    if (!_res.data) {
      return message.error("Error while getting data, please try again later");
    }

    setTableData(_res.data.rows);
    setPagination({
      ...params,
      total: _res.data.count,
      showTotal: (total) => `Entries found: ${total}`,
    });
    setLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter(
      { ...filter, [f]: v || undefined },
      setPagination({ ...pagination, current: 1 })
    );
  }

  function onSearch(v) {
    setSearch(v, setPagination({ ...pagination, current: 1 }));
  }

  const columns = [
    {
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 80,
      render: (_, data) => (
        <div>
          <img
            loading="lazy"
            src={
              data?.images?.length > 0
                ? `${config.api}/static/img/thumb-${data.images[0]}`
                : "/img/no-img.png"
            }
            alt="Product"
            className="block mx-auto w-20 h-20 object-contain rounded-sm bg-white"
          />
        </div>
      ),
    },
    {
      dataIndex: "info",
      key: "info",
      align: "left",
      title: "Wine",
      render: (_, data) => (
        <div>
          <span className="block align-middle text-base font-medium text-black">
            {data?.name}
          </span>
          {data?.sku && <Tag className="m-0 my-1">{data.sku}</Tag>}
          <span className="block text-sm font-normal text-neutral-400">
            Producer:{" "}
            <i className="not-italic text-neutral-600">
              {data?.producer?.name || "N/a"}
            </i>
          </span>
          <span className="block text-sm font-normal text-neutral-400">
            Region:{" "}
            <i className="not-italic text-neutral-600">
              {data?.region?.name || "N/a"}
            </i>
          </span>
        </div>
      ),
    },
    {
      dataIndex: "colour_id",
      key: "colour_id",
      align: "center",
      title: "Colour",
      render: (_, data) => (
        <Tag className="m-0">{data?.colour?.name || "Not specified"}</Tag>
      ),
      sorter: (a, b) => a?.colour_id - b?.colour_id,
    },
    {
      dataIndex: "variety_id",
      key: "variety_id",
      align: "center",
      title: "Variety",
      render: (_, data) => (
        <Tag className="m-0">{data?.variety?.name || "Not specified"}</Tag>
      ),
      sorter: (a, b) => a?.variety_id - b?.variety_id,
    },
    {
      dataIndex: "sweetness_id",
      key: "sweetness_id",
      align: "center",
      title: "Sweetness",
      render: (_, data) => (
        <Tag className="m-0">{data?.sweetness?.name || "Not specified"}</Tag>
      ),
      sorter: (a, b) => a?.sweetness_id - b?.sweetness_id,
    },
    {
      dataIndex: "project_id",
      key: "project_id",
      align: "center",
      title: "Project",
      render: (_, data) => (
        <Tag className="m-0">{data?.project?.name || "Not specified"}</Tag>
      ),
      sorter: (a, b) => a?.project_id - b?.project_id,
    },
    {
      dataIndex: "price",
      key: "price",
      align: "center",
      title: "Price",
      render: (data, all) => (
        <span className="block text-base font-medium text-black">
          {new Intl.NumberFormat(
            "en-GB",
            {
              style: "currency",
              currency: all?.currency || "EUR",
            },
            { maximumFractionDigits: 2, minimumFractionDigits: 2 }
          ).format(data || 0)}
        </span>
      ),
      sorter: (a, b) => a?.price - b?.price,
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      align: "center",
      render: (active) => (
        <Tag className="m-0" color={active ? "green" : "orange"}>
          {active ? "Published" : "Draft"}
        </Tag>
      ),
      sorter: (a, b) => a?.active - b?.active,
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (_, all) => (
        <Dropdown.Button
          menu={{
            items: [
              {
                label: "View Product",
                key: "view",
                onClick: () => setProductId(all.id),
                icon: <EyeOutlined />,
              },
            ],
          }}
          onClick={() => navigate(`/products/edit/${all?.id}`)}
        >
          <EditOutlined /> Edit
        </Dropdown.Button>
      ),
    },
  ];

  return (
    <div className="grid grid-cols-12 gap-4">
      {includes("search", showFilters) && (
        <div className="col-span-12 lg:col-span-4">
          <Filters.Search onSearch={onSearch} />
        </div>
      )}
      {includes("colour", showFilters) && (
        <div className="col-span-12 lg:col-span-2">
          <Filters.Colour onChange={onFilterChange} />
        </div>
      )}
      {includes("variety", showFilters) && (
        <div className="col-span-12 lg:col-span-2">
          <Filters.Variety onChange={onFilterChange} />
        </div>
      )}
      {includes("sweetness", showFilters) && (
        <div className="col-span-12 lg:col-span-2">
          <Filters.Sweetness onChange={onFilterChange} />
        </div>
      )}
      {includes("region", showFilters) && (
        <div className="col-span-12 lg:col-span-2">
          <Filters.Region onChange={onFilterChange} />
        </div>
      )}
      {includes("status", showFilters) && (
        <div className="col-span-12 lg:col-span-3">
          <Filters.Status onChange={onFilterChange} />
        </div>
      )}
      {includes("producer", showFilters) && (
        <div className="col-span-12 lg:col-span-3">
          <Filters.Producer onChange={onFilterChange} />
        </div>
      )}
      {includes("project", showFilters) && (
        <div className="col-span-12 lg:col-span-3">
          <Filters.Project onChange={onFilterChange} />
        </div>
      )}
      {includes("dates", showFilters) && (
        <div className="col-span-12 lg:col-span-3">
          <Filters.Dates onChange={onFilterChange} />
        </div>
      )}
      <div className="col-span-12">
        <Table
          showHeader={showHeader}
          onChange={getTableData}
          pagination={showPagination && pagination}
          dataSource={tableData}
          loading={loading}
          className="rounded-sm overflow-hidden w-full"
          rowKey="id"
          scroll={{ x: "max-content" }}
          columns={columns}
        />
      </div>
      <Modal
        centered
        destroyOnClose
        width={950}
        open={!!productId}
        onCancel={() => setProductId(null)}
        closable={false}
        okButtonProps={{ style: { display: "none" } }}
        cancelText="Done"
      >
        <View productId={productId} />
      </Modal>
    </div>
  );
}
