import { Card } from "antd";
import { Orders } from "@modules";
import { Breadcrumb } from "@components";

export default function OrdersPage() {
  return (
    <div className="container max-content">
      <Breadcrumb data={[{ title: "Orders", href: "/orders" }]} />
      <h1 className="mb-8 text-3xl">Orders</h1>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Card bordered={false}>
            <Orders.List />
          </Card>
        </div>
      </div>
    </div>
  );
}
