import { Varieties } from "@modules";
import { Breadcrumb } from "@components";
import { useParams } from "react-router-dom";

export default function EditPage() {
  const { id } = useParams();

  return (
    <div className="container max-content">
      <Breadcrumb
        data={[
          { title: "Varieties", href: "/varieties" },
          { title: "Edit Variety", href: `/varieties/edit/${id}` },
        ]}
      />
      <h1 className="mb-8 text-3xl">Edit Variety</h1>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Varieties.Edit id={id} />
        </div>
      </div>
    </div>
  );
}
