import { Form, Input } from "antd";

export default function DataFormItem(props) {
  return (
    <Form.Item {...props} name="address" className="w-full" label="Address">
      <Input.TextArea
        rows={3}
        minLength={3}
        className="w-full"
        placeholder="Enter customer address"
      />
    </Form.Item>
  );
}
