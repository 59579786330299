import { Form, Input, Divider, Tabs } from "antd";
import { useState, useEffect } from "react";
import { LANGUAGES } from "@utils/languages";

export default function DataFormItem(props) {
  const [locales, setLocales] = useState([]);

  useEffect(() => {
    LANGUAGES().then((res) => {
      setLocales(res.data.rows);
    });
  }, []);

  return (
    <div className="w-full">
      <Tabs
        items={locales?.map((locale) => ({
          key: locale.code,
          label: locale.name,
          forceRender: true,
          children: (
            <Form.Item
              {...props}
              name={
                locale?.code === "en" ? "name" : ["meta", locale.code, "name"]
              }
              className="w-full"
              label={`Name in ${locale.name}`}
              rules={[
                {
                  required: locale?.code === "en",
                  message: "Name is required",
                },
              ]}
            >
              <Input
                controls={false}
                className="w-full"
                placeholder={`Enter name in ${locale.name}`}
              />
            </Form.Item>
          ),
        }))}
      />
    </div>
  );

  // return (
  //   <Form.Item
  //     {...props}
  //     name="name"
  //     className="w-full"
  //     label="Name"
  //     rules={[
  //       {
  //         required: true,
  //         message: "Name is required",
  //       },
  //     ]}
  //   >
  //     <Input
  //       controls={false}
  //       minLength={3}
  //       className="w-full"
  //       placeholder="Enter wine name"
  //     />
  //   </Form.Item>
  // );
}
