import { message, Divider } from "antd";
import { Loading } from "@components";
import { useState, useEffect } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import config from "@config";
import * as API from "@api";

function ProductItem({ id, qty, key, ...props }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    API.Products.getOne({ id }).then((res) => {
      if (res?.error || !res) {
        return message.error(
          "One or more products can't be loaded. Please try again.",
          2
        );
      }

      setData(res?.data || null);
    });
  }, []);

  if (!data) {
    return <Loading compact />;
  }

  return (
    <div key={key} {...props} className="grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-4">
        <div className="flex justify-start items-center">
          <img
            src={
              data?.images[0]
                ? `${config.api}/static/img/thumb-${data.images[0]}`
                : "/img/no-img.png"
            }
            alt={data?.name}
            width={45}
            height={45}
            className="block mr-4 object-contain object-center bg-white border border-solid border-neutral-100"
          />
          <div>
            <span className="block text-base font-medium text-black">
              {data?.name} {data?.project && `(${data?.project?.name})`}
            </span>
            <span className="block text-sm font-normal text-neutral-600">
              SKU: {data?.sku || "N/a"}
            </span>
            <span className="block text-sm font-normal text-neutral-600">
              Price per unit:{" "}
              {new Intl.NumberFormat(
                "en-GB",
                {
                  style: "currency",
                  currency: data?.currency || "EUR",
                },
                { maximumFractionDigits: 2, minimumFractionDigits: 2 }
              ).format(data?.price || 0)}
            </span>
          </div>
        </div>
      </div>
      <div className="col-span-6 md:col-span-4 flex flex-col justify-center items-center">
        <span className="block text-sm text-neutral-400 font-normal">Qty</span>
        <span className="block text-base text-black font-medium">{qty}</span>
      </div>
      <div className="col-span-6 md:col-span-4 flex flex-col justify-center items-center">
        <span className="block text-sm text-neutral-400 font-normal">
          Amount
        </span>
        <span className="block text-base text-black font-medium">
          {new Intl.NumberFormat(
            "en-GB",
            {
              style: "currency",
              currency: data?.currency || "EUR",
            },
            { maximumFractionDigits: 2, minimumFractionDigits: 2 }
          ).format(qty * data?.price || 0)}
        </span>
      </div>
    </div>
  );
}

export default function Products({ data = [] }) {
  if (data?.length === 0) {
    return (
      <p className="text-base text-center text-neutral-500 block w-full my-10">
        No products to show.
      </p>
    );
  }

  return (
    <div className="flex flex-col gap-y-6 my-2">
      {data?.map((product) => (
        <ProductItem {...product} key={product.id} />
      ))}
    </div>
  );
}
