import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@components";
import { message, Form } from "antd";
import DataForm from "./form";
import * as API from "@api";
import { useAuth } from "@contexts";

export default function EditData() {
  const { currentUser } = useAuth();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    API.Users.getOne({ id }).then((res) => {
      if (res.error || !res) {
        return message.error("Unable to load data, please try again later.", 2);
      }

      setData(res.data);
      setLoading(false);
    });
  }, []);

  if (!data) {
    return <Loading compact />;
  }

  const onSave = async (values) => {
    setLoading(true);

    delete values.new_password;
    delete values.confirm_password;

    const meta = form.getFieldValue("meta");
    const normalizedValues = Object.entries(values).reduce(
      (acc, [key, value]) => {
        acc[key] = value !== undefined ? value : null;
        return acc;
      },
      {}
    );

    API.Users.update({
      id,
      ...normalizedValues,
      meta,
    })
      .then((res) => {
        if (res?.error || !res?.data) {
          throw new Error(res?.message || "Error updating user");
        }

        return message.success("User updated successfully", 1);
      })
      .catch((err) => message.error(err.message || "Error updating user", 2))
      .finally(() => setLoading(false));
  };

  const onPasswordChange = async () => {
    const newPassword = form.getFieldValue("new_password");
    const confirmPassword = form.getFieldValue("confirm_password");

    if (!newPassword) {
      return message.error("Please enter new password", 2);
    }

    if (newPassword !== confirmPassword) {
      return message.error("Passwords do not match", 2);
    }

    if (newPassword?.length < 6) {
      return message.error("Password should contain at least 6 characters", 2);
    }

    setLoading(true);

    API.Users.update({
      id,
      password: newPassword,
    })
      .then((res) => {
        if (res?.error || !res?.data) {
          throw new Error(res?.message || "Error updating user password");
        }

        return message.success("User password updated successfully", 1, () => {
          window.location.href = "/users";
        });
      })
      .catch((err) => message.error(err.message || "Error updating user", 2))
      .finally(() => setLoading(false));
  };

  const onDelete = () => {
    if (currentUser.id === parseInt(id)) {
      return message.error("Whoah! You cannot delete yourself :(", 2);
    }

    setLoading(true);

    API.Users.destroy({ id })
      .then((res) => {
        if (res?.error || !res?.data) {
          throw new Error(res?.message || "Error deleting user");
        }

        return message.success(
          "User deleted successfully",
          1,
          () => (window.location.href = "/users")
        );
      })
      .catch((err) => message.error(err.message || "Error deleting user", 2))
      .finally(() => setLoading(false));
  };

  return (
    <Form
      form={form}
      onFinish={onSave}
      onFinishFailed={(err) =>
        err?.errorFields?.map((msg) => message.error(msg.errors[0], 2))
      }
      layout="vertical"
      className="w-full form-container"
      initialValues={data}
    >
      <DataForm
        onPasswordChange={onPasswordChange}
        onDelete={onDelete}
        form={form}
        data={data}
        loading={loading}
      />
    </Form>
  );
}
