import { find } from "ramda";
import TextBlock from "./text";
import TextAreaBlock from "./textarea";
import ImageBlock from "./image";
import ImageCarouselBlock from "./carousel";
import HTMLBlock from "./html";
import Slider from "./slider";
import SwitchOption from "./options/switch";
import SelectOption from "./options/select";
import TextOption from "./options/text";
import TextAreaOption from "./options/textarea";

export default function Blocks({
  data = null,
  widgets = [],
  onChange,
  onOptionChange,
  ...props
}) {
  if (!data || !widgets) return null;

  const widget = find((w) => w.type === `widget:${data.type}`, widgets);
  const inputs = widget?.data?.inputs || [];
  const options = widget?.data?.options
    ? Object.keys(widget.data.options).map((key) => {
        return { key, data: widget.data.options[key] };
      })
    : [];

  return (
    <div className="grid grid-cols-12 gap-4 my-3">
      {inputs.map((input, key) => {
        switch (input.type) {
          case "text":
            return (
              <TextBlock
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          case "textarea":
            return (
              <TextAreaBlock
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          case "image":
            return (
              <ImageBlock
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          case "images":
            return (
              <ImageCarouselBlock
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          case "slider":
            return (
              <Slider input={input} onChange={onChange} key={key} data={data} />
            );

          case "html":
            return (
              <HTMLBlock
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          default:
            break;
        }
      })}
      {options.map((o) => {
        switch (o.data.type) {
          case "select":
            return (
              <SelectOption option={o} onChange={onOptionChange} data={data} />
            );

          case "radio":
            return (
              <SwitchOption option={o} onChange={onOptionChange} data={data} />
            );

          case "text":
            return (
              <TextOption option={o} onChange={onOptionChange} data={data} />
            );

          case "textarea":
            return (
              <TextAreaOption
                option={o}
                onChange={onOptionChange}
                data={data}
              />
            );

          default:
            break;
        }
      })}
    </div>
  );
}
