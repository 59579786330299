import { Form, Input } from "antd";
import { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function Details({ form, ...props }) {
  const [terms, setTerms] = useState(form.getFieldValue("terms") || "");
  const [privacy, setPrivacy] = useState(form.getFieldValue("privacy") || "");

  useEffect(() => {
    form.setFieldsValue({ terms });
  }, [terms]);

  useEffect(() => {
    form.setFieldsValue({ privacy });
  }, [privacy]);

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <span className="block text-xs font-medium text-neutral-800 mb-2">
          <i className="not-italic text-rose-600">*</i> Terms &amp; Conditions
          text
        </span>
        <CKEditor
          key="terms"
          config={{
            toolbar: [
              "undo",
              "redo",
              "|",
              "heading",
              "|",
              "bold",
              "italic",
              "link",
              "bulletedList",
              "numberedList",
              "|",
              "indent",
              "outdent",
              "|",
              "blockQuote",
              "insertTable",
            ],
            image: {},
          }}
          editor={ClassicEditor}
          data={terms || ""}
          onChange={(_, editor) => setTerms(editor.getData())}
        />
      </div>
      <div className="col-span-12">
        <span className="block text-xs font-medium text-neutral-800 mb-2">
          <i className="not-italic text-rose-600">*</i> Privacy Policy text
        </span>
        <CKEditor
          key="privacy"
          config={{
            toolbar: [
              "undo",
              "redo",
              "|",
              "heading",
              "|",
              "bold",
              "italic",
              "link",
              "bulletedList",
              "numberedList",
              "|",
              "indent",
              "outdent",
              "|",
              "blockQuote",
              "insertTable",
            ],
            image: {},
          }}
          editor={ClassicEditor}
          data={privacy || ""}
          onChange={(_, editor) => setPrivacy(editor.getData())}
        />
      </div>
      <div className="col-span-12">
        <Form.Item
          {...props}
          name="age"
          className="w-full"
          label="Age restriction banner text"
          rules={[
            {
              required: true,
              message: "Age restriction banner text is required",
            },
          ]}
        >
          <Input.TextArea
            rows={3}
            className="w-full"
            placeholder="Age restriction banner text"
          />
        </Form.Item>
      </div>
    </div>
  );
}
