import Name from "./name";
import Project from "./project";
import Alcohol from "./alcohol";
import Colour from "./colour";
import Variety from "./variety";
import Producer from "./producer";
import Region from "./region";
import Sweetness from "./sweetness";
import Price from "./price";
import Currency from "./currency";
import Status from "./status";
import Volume from "./volume";
import Vintage from "./vintage";
import SKU from "./sku";

export default function Details() {
  return (
    <div className="grid grid-cols-12 gap-4">
      {/* <div className="col-span-12 md:col-span-3">
        <SKU />
      </div> */}
      <div className="col-span-12 md:col-span-4">
        <Name />
      </div>
      <div className="col-span-12 md:col-span-2">
        <SKU />
      </div>
      <div className="col-span-12 md:col-span-2">
        <Project />
      </div>
      <div className="col-span-12 md:col-span-2">
        <Status />
      </div>
      <div className="col-span-12 md:col-span-2">
        <Volume />
      </div>
      <div className="col-span-12 md:col-span-3">
        <Vintage />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-3">
        <Alcohol />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-3">
        <Colour />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-3">
        <Variety />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-3">
        <Producer />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-3">
        <Region />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-3">
        <Sweetness />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-3">
        <Price />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-3">
        <Currency />
      </div>
    </div>
  );
}
