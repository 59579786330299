import { Statistic, message } from "antd";
import { Loading } from "@components";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import * as API from "@api";

export default function StatsCard({
  className,
  title = "New orders",
  filter = {},
  from = dayjs().subtract(1, "day").startOf("day"), // Yesterday basically
  to = dayjs(),
}) {
  const [values, setValues] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    API.Orders.getAll({
      filter: {
        ...filter,
        created_at: {
          from: from.format("YYYY-MM-DD"),
          to: to.format("YYYY-MM-DD"), // Today
        },
      },
      limit: 1,
    })
      .then((res) => {
        setValues(res?.data?.count || 0);
        setLoading(false);
      })
      .catch(() => message.error("Error loading data. Try again later.", 2));
  }, []);

  if (loading) return <Loading compact />;

  return <Statistic className={className} title={title} value={values} />;
}
