import { Input } from "antd";

export default function Widget({ option, onChange, data }) {
  return (
    <div className="col-span-12">
      <span className="block text-xs font-medium text-neutral-800 mb-2">
        {option.data.label}{" "}
        {option.data.required && <i className="not-italic text-rose-600">*</i>}
      </span>
      <Input.TextArea
        rows={3}
        className="w-full"
        placeholder={"Enter " + option.data.label}
        onChange={(val) => {
          onChange(option.key, val);
        }}
        key={option.key}
        value={data?.options?.[option.key] || null}
      />
    </div>
  );
}
