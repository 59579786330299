import { Divider, Card, Button } from "antd";
import Details from "./details";

export default function NewEntry({ loading, onPasswordChange, onDelete }) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 lg:col-span-6 h-full">
        <Card title="Change Details" className="h-full" bordered={false}>
          <div className="grid grid-cols-12 gap-x-4 gap-y-2">
            <div className="col-span-12">
              <Details />
            </div>
          </div>
          <Divider />
          <div className="flex flex-wrap justify-between items-center">
            <Button loading={loading} type="primary" htmlType="submit">
              Create User
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
}
