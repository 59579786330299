import { Form, InputNumber } from "antd";

export default function Alcohol(props) {
  return (
    <Form.Item {...props} name="vintage" className="w-full" label="Vintage">
      <InputNumber
        controls={false}
        minLength={4}
        maxLength={4}
        className="w-full"
        placeholder="Enter vintage"
      />
    </Form.Item>
  );
}
