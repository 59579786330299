import { useEffect, useState } from "react";
import { Table, message, Tag, Modal, Dropdown } from "antd";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import { pathOr, includes } from "ramda";
import { useNavigate } from "react-router-dom";
import View from "../view";
import dayjs from "dayjs";
import * as API from "@api";
import * as Filters from "./filters";

export default function DataTable({
  showHeader = true,
  showFilters = ["search", "shipping", "dates"],
  defaultFilterValues = null,
  pageSize = 10,
  showPagination = true,
}) {
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({
    id: pathOr(undefined, ["id"], defaultFilterValues),
    created_at: pathOr(undefined, ["created_at"], defaultFilterValues),
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
    showTotal: (total) => `Entries found: ${total}`,
  });

  useEffect(() => {
    getTableData();
  }, [filter, search]);

  async function getTableData(params = pagination, _, sorter) {
    setLoading(true);
    const _res = await API.Sweetnesses.getAll({
      s: search,
      filter,
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
      order: sorter?.field && {
        [sorter?.field]: sorter?.order === "ascend" ? "ASC" : "DESC",
      },
    });

    if (!_res.data) {
      return message.error("Error while getting data, please try again later");
    }

    setTableData(_res.data.rows);
    setPagination({
      ...params,
      total: _res.data.count,
      showTotal: (total) => `Entries found: ${total}`,
    });
    setLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter(
      { ...filter, [f]: v || undefined },
      setPagination({ ...pagination, current: 1 })
    );
  }

  function onSearch(v) {
    setSearch(v, setPagination({ ...pagination, current: 1 }));
  }

  const columns = [
    {
      dataIndex: "name",
      key: "name",
      align: "left",
      title: "Name",
      render: (data, all) => (
        <span className="block align-middle text-base font-medium text-black">
          {data}
        </span>
      ),
    },
    {
      title: "Date Created",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      render: (data) => (
        <span className="m-0 leading-tight">
          <i className="text-neutral-500 not-italic">
            {dayjs(data).format("HH:mm")}
          </i>{" "}
          &middot; {dayjs(data).format("DD MMM YYYY")}
        </span>
      ),
      sorter: (a, b) => dayjs(a.created_at) - dayjs(b.created_at),
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      align: "center",
      render: (data) => (
        <span className="m-0 leading-tight">
          <i className="text-neutral-500 not-italic">
            {dayjs(data).format("HH:mm")}
          </i>{" "}
          &middot; {dayjs(data).format("DD MMM YYYY")}
        </span>
      ),
      sorter: (a, b) => dayjs(a.updated_at) - dayjs(b.updated_at),
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (_, all) => (
        <div className="flex justify-end">
          <Dropdown.Button
            className="w-fit"
            menu={{
              items: [
                {
                  label: "View Sweetness",
                  key: "view",
                  onClick: () => setId(all.id),
                  icon: <EyeOutlined />,
                },
              ],
            }}
            onClick={() => navigate(`/sweetnesses/edit/${all?.id}`)}
          >
            <EditOutlined /> Edit
          </Dropdown.Button>
        </div>
      ),
    },
  ];

  return (
    <div className="grid grid-cols-12 gap-4">
      {includes("search", showFilters) && (
        <div className="col-span-12 lg:col-span-6">
          <Filters.Search onSearch={onSearch} />
        </div>
      )}
      {includes("dates", showFilters) && (
        <div className="col-span-12 lg:col-span-6">
          <Filters.Dates onChange={onFilterChange} />
        </div>
      )}
      <div className="col-span-12">
        <Table
          showHeader={showHeader}
          onChange={getTableData}
          pagination={showPagination && pagination}
          dataSource={tableData}
          loading={loading}
          className="rounded-sm overflow-hidden w-full"
          rowKey="id"
          scroll={{ x: "max-content" }}
          columns={columns}
        />
      </div>
      <Modal
        centered
        destroyOnClose
        width={950}
        open={!!id}
        onCancel={() => setId(null)}
        closable={false}
        okButtonProps={{ style: { display: "none" } }}
        cancelText="Done"
      >
        <View id={id} />
      </Modal>
    </div>
  );
}
